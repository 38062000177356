import { Box, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const RepoProgressSkeleton = () => {
  const theme = useTheme();
  
  return (
    <Box sx={{ 
      border: `1px solid ${theme.palette.divider}`, 
      width: '100%', 
      borderRadius: '10px', 
      padding: '15px'
    }}>
      <Skeleton width="60%" height={24} sx={{ marginBottom: 1 }} />
      <Skeleton width="100%" height={8} sx={{ borderRadius: 4 }} />
    </Box>
  );
};

export default RepoProgressSkeleton; 