import React, { useContext, useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import MenuSearch from "@/components/SearchBar/MenuSearch";
import { AxiosResponse } from "axios";
import { get_root_hierarchy, search_node } from "@/services/Blar/Repo_graph";
import { Context } from "@/contexts/ContextProvider";
import FileStructure from "../Chat menu/FileStructure";
import { ChatRoom, FileNode } from "../types";
import CustomTooltip from "@/components/Tooltip/CustomTooltip"; // Import the CustomTooltip component
import CustomToggleButton from "@/components/Buttons/ToggleButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTagify } from "../../context/TagifyContext";
import { Chat } from "@mui/icons-material";
import { ChatRoomContext } from "@/contexts/ChatRoomContextProvider";

interface ChatMenuProps {
  chatRoomId: number;
  handleChooseStartingPoint: (node: FileNode) => void;
  handleClickNewChat: (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ) => void;
}

const ChatMenu: React.FC<ChatMenuProps> = ({
  handleChooseStartingPoint,
  handleClickNewChat,
}) => {
  const { showMessage } = useContext(Context);
  const { chatRoom } = useContext(ChatRoomContext);
  const [nodeOptions, setNodeOptions] = useState<FileNode[]>([]);
  const [selectedNode, setSelectedNode] = useState<FileNode | null>(null);
  const [loadingNodes, setLoadingNodes] = useState<boolean>(false);
  const [loadingFilesCount, setLoadingFilesCount] = useState<number>(0);
  const [fileStructure, setFileStructure] = useState<FileNode[]>([]);
  const tagifyRef = useTagify();

  useEffect(() => {
    const fetchFileStructure = async () => {
      try {
        setLoadingFilesCount((prev) => prev + 1);
        const response = await get_root_hierarchy(chatRoom!.environment);
        const data = response.data;
        setFileStructure(data as FileNode[]);
      } catch (error) {
        showMessage("error", "Failed to get files");
      } finally {
        setLoadingFilesCount((prev) => prev - 1);
      }
    };

    if (chatRoom) {
      fetchFileStructure();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoom])

  const handleSearchChange = useCallback(async (value: string) => {
    if (!value || chatRoom === undefined) {
      setNodeOptions([]);
      return;
    }

    try {
      setLoadingNodes(true);
      const response: AxiosResponse = await search_node(value || "", chatRoom.environment);
      const options = response.data as FileNode[];
      setNodeOptions(options);
    } catch (error) {
      console.error(error);
      showMessage("error", "Failed to search");
    } finally {
      setLoadingNodes(false);
    }
  }, [chatRoom]);

  const handleSelectStartingPoint = () => {
    if (!selectedNode || !tagifyRef.current) return;

    const existingTags = tagifyRef.current.value;
    const isNodeAlreadyAdded = existingTags.some(
      (tag: any) => tag.id === selectedNode.node_id
    );

    if (!isNodeAlreadyAdded) {
      handleChooseStartingPoint(selectedNode);
      
      const tagData = {
        value: selectedNode?.name,
        id: selectedNode?.node_id,
        path: selectedNode?.path?.replace("temp.repos.", ""),
        type: selectedNode?.type,
        mappedValue: selectedNode?.name,
        tagType: "node",
      };

      try {
        tagifyRef.current.DOM.input.focus();
        tagifyRef.current.setStateSelection();

        var tagElm = tagifyRef.current.createTagElem(tagData);
        tagifyRef.current.injectAtCaret(tagElm);
        var elm = tagifyRef.current.insertAfterTag(tagElm);
        tagifyRef.current.placeCaretAfterNode(elm);
      } catch (error) {
        console.error("Error adding tag:", error);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        height: "100vh",
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        mt={1}
        width={"100%"}
      >
        <IconButton
          sx={{ height: "35px", width: "35px" }}
          color="primary"
          onClick={() => {
            window.history.back();
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          alignContent={"center"}
          paddingInline={1}
        >
          {chatRoom ? chatRoom.name : <CircularProgress size={20} />}
        </Typography>
        <CustomToggleButton
          id="new-chat"
          value="new"
          selected={false}
          handleClick={handleClickNewChat}
        >
          <img src="/icons/edit_square.svg" alt="Logo" />
        </CustomToggleButton>
      </Box>
      <Box
        mt={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flexGrow: 1,
          maxHeight: "calc(100vh - 200px)",
        }}
      >
        <MenuSearch
          onChange={handleSearchChange}
          options={selectedNode ? [...nodeOptions, selectedNode!] : nodeOptions}
          placeholder="Search path..."
          loading={loadingNodes}
          value={selectedNode}
          setSelectedNode={setSelectedNode}
          selectedNode={selectedNode}
          selectedRepo={"all"}
        />
        <Paper
          id="file-explorer"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 1,
            marginTop: 1,
            backgroundColor: "#0B0E12",
            flexGrow: 1,
            overflowY: "auto",
          }}
        >
          <FileStructure
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            structure={fileStructure}
            setStructure={setFileStructure}
            loadingCount={loadingFilesCount}
            setLoadingCount={setLoadingFilesCount}
          />
        </Paper>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CustomTooltip
          title={
            selectedNode
              ? `Selected Node: ${selectedNode.name}`
              : "Select a file, class or function as starting node"
          }
        >
          <span>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              id="start-chat-button"
              disabled={!selectedNode}
              onClick={() => handleSelectStartingPoint()}
            >
              Select starting point
            </Button>
          </span>
        </CustomTooltip>
      </Box>
    </Box>
  );
};

export default ChatMenu;
