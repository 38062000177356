import React, { useRef } from 'react';
import Subscriptions from '@/components/Subscription';
import { Box } from '@mui/material';

const Pricing = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Box height='100%' display='flex' flexDirection='column' alignItems='center' justifyContent={'center'} ml={10} mr={10} ref={containerRef}>
      <Subscriptions containerRef={containerRef}/>
    </Box>
  );
};

export default Pricing;