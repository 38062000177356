import { github_app_installation } from "@/services/Blar/Integrations";
import { AlertColor } from "@mui/material";
import { URLSearchParams } from "url";

const REDIRECT_TIMEOUT = 3 * 1000;

export function redirectToGitHubApp() {
  const githubAuthUrl = process.env.REACT_APP_GITHUB_APP_URL!;

  window.location.href = githubAuthUrl;
}

const handleGithubIntegration = async (
  showMessage: (type: AlertColor, message: string) => void,
  searchParams: URLSearchParams
) => {
  try {
    const installationId = searchParams.get("installation_id");
    const code = searchParams.get("code");

    if (!installationId || !code) {
      return;
    }

    const payload = {
      installation_id: installationId!,
      code: code!,
    };
    const response = await github_app_installation(payload);
    const { message, redirect_url } = response.data;
    showMessage("success", message);
    setTimeout(() => (window.location.href = redirect_url), REDIRECT_TIMEOUT);
  } catch (error) {
    showMessage("error", "Failed to connect GitHub");
  }
};

export const handleRedirectToGitHubApp = async (
  showMessage: (type: AlertColor, message: string) => void
) => {
  showMessage("info", "Redirecting to GitHub for authentication");
  setTimeout(() => redirectToGitHubApp(), REDIRECT_TIMEOUT);
  return;
};

export default handleGithubIntegration;
