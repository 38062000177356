import { Chip } from "@mui/material";
import { PropTypes } from "@mui/material";

export type ImpactType = "low" | "medium" | "high";

interface ImpactChipProps {
  impact: ImpactType;
}

const ImpactChip = ({ impact }: ImpactChipProps) => {
  const chipStyle = {
    borderRadius: "5px",
    border: "2px solid",
    fontFamily: "Ubuntu Sans Mono", 
    fontWeight: "bold",
    "& .MuiChip-label": {
      py: "2px",
      px: "4px",
      fontSize: "16px"
    }
  };

  const impactConfig = {
    low: {
      color: "blarSuccess" as PropTypes.Color,
      label: "Low"
    },
    medium: {
      color: "blarHigh" as PropTypes.Color, 
      label: "Medium"
    },
    high: {
      color: "blarCritical" as PropTypes.Color,
      label: "High" 
    }
  };

  const config = impactConfig[impact as keyof typeof impactConfig];

  return (
    <Chip
      sx={{
        ...chipStyle,
        borderColor: `${config.color}.main`,
        color: `${config.color}.main`
      }}
      variant="outlined"
      label={config.label}
    />
  );
};

export default ImpactChip;