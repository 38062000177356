import React from 'react';
import { Chip } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

interface LinkBadgeProps {
  href?: string;
  label: string;
}

const LinkBadge: React.FC<LinkBadgeProps> = ({ href, label }) => {
  if (!href) return null;
  return (
    <Chip
      component="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      label={label}
      clickable
      color="primary"
      variant="filled"
      icon={<LaunchIcon sx={{ fontSize: "14px" }}/>}
      sx={{
        borderRadius: "5px",
        border: "2px solid",
        fontFamily: "Ubuntu Sans Mono",
        "& .MuiChip-icon": {
          marginRight: "4px",
        },
        "& .MuiChip-label": {
          py: "2px",
          px: "4px",
          fontSize: "14px"
        }
      }}
    />
  );
};

export default LinkBadge; 