import ContinueButton from '@/components/Buttons/ContinueButton';
import Subscriptions from '@/components/Subscription';
import { Box } from '@mui/material';
import { Context } from '@/contexts/ContextProvider';
import React, { useContext, useRef } from 'react';
import useStyles from "./styles/Integrations";

interface SelectPlanProps {
  continueOnboarding: () => void;
}

const SelectPlan: React.FC<SelectPlanProps> = ({
  continueOnboarding,
}) => {
  const { companyTier } = useContext(Context);
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Box className={classes.root} ref={containerRef}>
      <Subscriptions containerRef={containerRef}/>
      <br/>
      <ContinueButton
        continueOnboarding={continueOnboarding}
        disabled={ companyTier === 0}
      />
    </Box>
  );
};

export default SelectPlan;