import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Chip,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { Repository } from "@/interfaces/IBackendOutputs";
import { AxiosResponse } from "axios";
import { github_list_repo_branches, github_list_repos, github_repository_info } from "@/services/Blar/Integrations";
import { Context } from "@/contexts/ContextProvider";
import { LoadingButton } from "@mui/lab";
import GitHubIcon from "@mui/icons-material/GitHub";
import { CreateRepoParams } from "@/pages/Settings/components/Repos/types";
import { create_repo } from "@/services/Blar/Repo_graph";

interface RepositoryBranchSelectorProps {
  setHasRepo: React.Dispatch<React.SetStateAction<boolean>>;
}

const RepositoryBranchSelector: React.FC<RepositoryBranchSelectorProps> = ({ setHasRepo }) => {
  const { showMessage } = useContext(Context);
  const [repositories, setRepositories] = useState<Repository[]>([]);
  const [branches, setBranches] = useState<string[]>([]);
  const [selectedRepository, setSelectedRepository] = useState<number>(-1);
  const [selectedBranches, setSelectedBranches] = useState<string[]>([]);
  const [loadingRepos, setLoadingRepos] = useState(false);
  const [isRepositoryTooBig, setIsRepositoryTooBig] = useState(false);
  const [repositoryPage, setRepositoryPage] = useState(1);
  const [branchesPage, setBranchesPage] = useState(1);
  const [hasMoreRepos, setHasMoreRepos] = useState(true);
  const [hasMoreBranches, setHasMoreBranches] = useState(true);

  const fetchNextDataRepository = useCallback(async (page: number) => {
    try {
      setLoadingRepos(true);
      const repositoriesResponse: AxiosResponse = await github_list_repos(page);
      if (repositoriesResponse.status === 200) {
        setRepositories((prev) => [...prev, ...repositoriesResponse.data.results]);
        setRepositoryPage(repositoriesResponse.data.page + 1);
        setHasMoreRepos(repositoriesResponse.data.total_count > repositories.length);
      }
    } catch (error) {
      showMessage("error", "Failed to get repositories");
    } finally {
      setLoadingRepos(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repositories]);

  useEffect(() => {
    fetchNextDataRepository(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNextDataBranches = useCallback(async (repo: string, page: number) => {
    try {
      setLoadingRepos(true);
      const branchesResponse: AxiosResponse = await github_list_repo_branches(repo, page);
      if (branchesResponse.status === 200) {
        setBranches((prev) => [...prev, ...branchesResponse.data.results]);
        setBranchesPage(branchesResponse.data.page + 1);
        setHasMoreBranches(branchesResponse.data.total_count > branches.length);
      }
    } catch (error) {
      showMessage("error", "Failed to get branches");
    } finally {
      setLoadingRepos(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches]);

  useEffect(() => {
    // get repository branches
    setBranchesPage(1);
    setBranches([]);
    if (selectedRepository !== -1) {
      fetchNextDataBranches(repositories[selectedRepository].fullName, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repositories, selectedRepository]);


  const handleRepositoryChange = async (
    event: SelectChangeEvent<number | null>
  ) => {
    const value = parseInt(event.target.value as string, 10);
    if (isNaN(value)) {
      return;
    }
    
    setSelectedRepository(value);
    setSelectedBranches([]);
    const repositoryName = repositories[value].fullName;
    const org = repositoryName.split("/")[0];
    const repo = repositoryName.split("/")[1];
    try {
      const repositoryInfoRequest = await github_repository_info(org, repo);
      const repositoryInfo = repositoryInfoRequest.data;
      const size = repositoryInfo.size;
      // check if the repository is too big, 2GB
      if (size > 2000000) {
        setIsRepositoryTooBig(true);
      } else {
        setIsRepositoryTooBig(false);
      }
    } catch (error) {}
  };

  const handleBranchChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    // Limit to exactly 2 selections
    if (Array.isArray(value)) {
      if (value.length <= 2) {
        setSelectedBranches(value);
      }
    }
  };

  const handleAddRepository = async () => {
    const allReady = selectedRepository !== -1 && 0 < selectedBranches.length && selectedBranches.length < 3;

    if (allReady) {
      try {
        setLoadingRepos(true);
        if (selectedBranches.length === 1) {
          selectedBranches.push(selectedBranches[0]);
        }
        const addRepoParams: CreateRepoParams = {
          github_url: repositories[selectedRepository].url,
          main_branch: selectedBranches[0],
          development_branch: selectedBranches[1],
        };
        const createRepoResponse = await create_repo(addRepoParams);
        console.log(createRepoResponse);
        if (createRepoResponse.status === 201) {
          showMessage("success", "Repository added successfully");
          setHasRepo(true);
        }
        setSelectedRepository(-1);
      } catch (error) {
        showMessage("error", "Failed to add repository");
      } finally {
        setLoadingRepos(false);
      }
    }
  };

  return (
    <Box width={"100%"}>
      <FormControl fullWidth>
        <InputLabel id="repository_select" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Repository</InputLabel>
        <Select
          id="repository_select"
          color="secondary"
          value={selectedRepository}
          label="Repository"
          placeholder="Select a repository"
          onChange={handleRepositoryChange}
          MenuProps={{
            PaperProps: {
              onScroll: (event: React.UIEvent<HTMLElement>) => {
                const bottom =
                  event.currentTarget.scrollHeight ===
                  event.currentTarget.scrollTop + event.currentTarget.clientHeight;
                if (bottom && hasMoreRepos && !loadingRepos) {
                  fetchNextDataRepository(repositoryPage);
                }
              },
              style: { maxHeight: 300, overflowY: "auto" },
            },
          }}
        >
          {repositories.map((repo, index) => (
            <MenuItem key={index} value={index}>
              {repo.fullName}
            </MenuItem>
          ))}
        </Select>
        <br/>
      </FormControl>
      
      {isRepositoryTooBig && (
        <Typography variant="body1" color="error">
          Currently, we don't support repositories bigger than 2GB. Please select another one.
        </Typography>
      )}
      
      {selectedRepository !== -1 && (
        <>
          <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
            Select one or two branches that we will be watching and reviewing the code on the PRs made to them.
          </Typography>
          <br/>
          <FormControl fullWidth>
            <InputLabel id="branches_select" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              Branches
            </InputLabel>
            <Select
              id="branches_select"
              multiple
              value={selectedBranches}
              label="Branches"
              onChange={handleBranchChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value, index) => (
                    <Chip 
                      key={index} 
                      label={value}
                      color={"primary"}
                      variant="outlined"
                      size="small"/>
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  onScroll: (event: React.UIEvent<HTMLElement>) => {
                    const bottom =
                      event.currentTarget.scrollHeight ===
                      event.currentTarget.scrollTop + event.currentTarget.clientHeight;
                    if (bottom && hasMoreBranches && !loadingRepos) {
                      fetchNextDataBranches(repositories[selectedRepository].fullName, branchesPage);
                    }
                  },
                  style: { maxHeight: 300 },
                },
              }}
            >
              {branches.map((branch) => (
                <MenuItem 
                  key={branch} 
                  value={branch}
                  disabled={selectedBranches.length >= 2 && !selectedBranches.includes(branch)}
                >
                  {branch}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      <LoadingButton
        variant="contained"
        color="secondary"
        startIcon={<GitHubIcon />}
        style={{ fontSize: "20px", margin: "16px 0" }}
        onClick={handleAddRepository}
        loading={loadingRepos}
        disabled={
          selectedRepository === -1 ||
          selectedBranches.length === 0 ||
          isRepositoryTooBig
        }
      >
        Add Repository
      </LoadingButton>
    </Box>
  );
};

export default RepositoryBranchSelector;