import React from 'react';
import { Box, Chip } from '@mui/material';

interface BranchBoxesProps {
  mainBranch: string;
  developmentBranch: string;
}

export default function BranchBoxes({ mainBranch, developmentBranch }: BranchBoxesProps) {
  const isOneBranchBeenWatch = mainBranch === developmentBranch;

  return (
    <Box display="flex" gap={1} alignItems="center" justifyContent="left">
      {mainBranch && (
        <Chip
          label={mainBranch}
          color="primary"
          size="small"
        />
      )}
      {developmentBranch && !isOneBranchBeenWatch && (
        <Chip
          label={developmentBranch}
          color="primary"
          size="small"
        />
      )}
    </Box>
  );
}
