import { Box, LinearProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { formatRepoName } from "../helpers";
import { useChecklistContext } from "../contexts/ChecklistContext";
import { RepoChecklistPercentage } from "./RepoList";
interface RepoProgressProps {
  repo: RepoChecklistPercentage;
}

const RepoProgress = ({ repo,}: RepoProgressProps) => {
  const theme = useTheme();
  const { handleSelectRepo, selectedRepo } = useChecklistContext();

  const isSelected = repo.repo_id === selectedRepo?.repo_id;

  return (
    <Box sx={{ 
      border: `1px solid ${theme.palette.divider}`, 
      width: '100%', 
      backgroundColor: isSelected ? theme.palette.divider : theme.palette.background.paper, 
      borderRadius: '10px', 
      padding: '15px',
      cursor: 'pointer'
    }} 
    onClick={() => handleSelectRepo(repo)}
    >
      <Typography fontFamily="Ubuntu Sans Mono" marginBottom={1}>
        {formatRepoName(repo.name)}
      </Typography>
      <LinearProgress 
        variant="determinate" 
        value={(repo.repo_status_percentage * 100)}      
        sx={{
          height: 8,
          borderRadius: 4,
          backgroundColor: '#FFFFFF',
          '& .MuiLinearProgress-bar': {
            borderRadius: 4,
            backgroundColor: repo.repo_status_percentage === 0 ? '#FFFFFF' : '#36CF8F'
          }
        }}
      />
    </Box>
  );
};

export default RepoProgress;
