import { Search as SearchIcon } from '@mui/icons-material';
import { Box, IconButton, InputBase, alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SearchBar = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '10px',
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
        },
        '&:focus-within': {
          backgroundColor: theme.palette.background.paper,
        },
        marginRight: 2,
        marginLeft: 0,
        width: '100%',
        marginBottom: 2,
      }}
      >
      <InputBase
        placeholder="Search repos..."
        sx={{
          color: 'inherit',
          width: '100%',
          '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: '15px',
            width: '100%',
          },
        }}
      />
      <IconButton
        sx={{
          height: '40px',
          position: 'absolute',
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          right: 0,
          top: 0,
        }}
      >
        <SearchIcon sx={{ color: theme.palette.divider }} />
      </IconButton>
    </Box>
  );
};

export default SearchBar;
