import { blarClient } from "./Client";

export const list_repo_checklist_percentage = async () => {
  return blarClient.request("get", `/diagnostics/get-criteria-status-percentage/`,);
};

export const list_repo_checklist_items = async (repo_id: string) => {
  return blarClient.request("get", `/diagnostics/get-criteria-status?repo_id=${repo_id}`);
};

export const get_criteria_data = async (repo_id: string, criteria_id: string) => {
  return blarClient.request("get", `/diagnostics/get-step-status-for-criteria?repo_id=${repo_id}&criteria_id=${criteria_id}`);
};

export const run_steps_for_criteria_status = async (criteria_id: string, repo_id: string) => {
  return blarClient.request("patch", `/diagnostics/run-checker-for-step-status/`, { criteria_id, repo_id });

}
export const get_remediate_step = async (step_status_id: string) => {
  return blarClient.request("get", `/diagnostics/get-remediate?step_status_id=${step_status_id}`);
};

export const mark_step_status_as_completed_manually = async (step_status_id: string, repo_id: string) => {
  return blarClient.request("patch", `/diagnostics/mark-step-status-as-manually-completed/`, {
    step_status_id,
    repo_id
  });
};

export const mark_step_status_as_failed_manually = async (step_status_id: string, repo_id: string) => {
  return blarClient.request("patch", `/diagnostics/mark-step-status-as-manually-failed/`, {
    step_status_id,
    repo_id
  });
};

export const get_criteria_status_by_id = async (repo_id: string, criteria_id: string) => {
  return blarClient.request("get", `/diagnostics/get-criteria-status-status?repo_id=${repo_id}&criteria_id=${criteria_id}`);
};