import "./App.css";

import { Box, CircularProgress } from "@mui/material";
import { Context, ContextProvider } from "@/contexts/ContextProvider";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { initializeGA, trackPageView } from "./helpers/analytics";
import AuthForm from "./pages/Auth/AuthForm";
import AuthInvitationForm from "./pages/Auth/AuthInvitationForm";
import Chat from "./pages/Chat/Chat";
import CssBaseline from "@mui/material/CssBaseline";
import GeneralTheme from "./Theme";
import { NotificationsContextProvider } from "@/contexts//NotificationContextProvider";
import Onboarding from "./pages/Auth/Onboarding";
import Protected from "./ProtectedRoute";
import Redirect from "./pages/Redirect/Redirect";
import Settings from "./pages/Settings/Settings";
import Pricing from "./pages/Pricing/Pricing";
import WikiPage from "./pages/Wiki/WikiPage";
import { blarClient } from "./services/Blar/Client";
import ChecklistPage from "@/pages/Checklist/Checklist";
import ChecklistTestPage from "@/pages/Checklist/ChecklistTest";

const theme = createTheme(GeneralTheme);

function AppLogic() {
  const {
    isUserSignIn,
    isOnboarding,
    isActive,
    isGuest,
    signIn,
    signOut,
    refreshAccessToken,
    access,
    refresh,
  } = useContext(Context);
  const location = useLocation();

  const [loadingRefresh, setLoadingRefresh] = useState(true);

  useEffect(() => {
    blarClient.init(signIn, signOut, refreshAccessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserSignIn]);

  useEffect(() => {
    const refreshToken = async () => {
      setLoadingRefresh(true);
      try {
        if (isUserSignIn) {
          await refreshAccessToken();
        }
      } catch (error) {
        console.error("Failed to refresh access token:", error);
        signOut();
      }
      setLoadingRefresh(false);
    };
    refreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_redirect_uri = () => {
    let getRedirectUri;
    if (!isUserSignIn) getRedirectUri = "/login";
    if (isOnboarding) getRedirectUri = "/onboarding";
    if (!isActive && isUserSignIn && !isOnboarding)
      getRedirectUri = "/subscribe";
    if (isUserSignIn && !isOnboarding && isActive) getRedirectUri = "/checklist";
    return getRedirectUri;
  };

  const isAllowed = () => {
    return isUserSignIn && !isOnboarding && isActive;
  };

  const isAllowedNotGuest = () => {
    return isUserSignIn && !isOnboarding && isActive && !isGuest;
  };

  initializeGA(process.env.REACT_APP_GA_TRACKING_ID);

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  if (loadingRefresh)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100vh"
        width="100vw"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<AuthForm />} />
        <Route path="/invitation" element={<AuthInvitationForm />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route
          path="/chat/:chatRoomId?"
          element={
            <Protected isAllowed={isAllowed()} redirectTo={get_redirect_uri()}>
              <NotificationsContextProvider access={access} refresh={refresh}>
                <Chat />
              </NotificationsContextProvider>
            </Protected>
          }
        />
        <Route
          path="/checklist/:repoName?/*"
          element={
            <Protected isAllowed={isAllowedNotGuest()} redirectTo={get_redirect_uri()}>
              <ChecklistPage />
            </Protected>
          }
        />
        <Route
          path="/checklist/:repoName/check/:checkId"
          element={
            <Protected isAllowed={isAllowedNotGuest()} redirectTo={get_redirect_uri()}>
              <ChecklistTestPage />
            </Protected>
          }
        />
        <Route
          path="/settings"
          element={
            <Protected
              isAllowed={isAllowedNotGuest()}
              redirectTo={get_redirect_uri()}
            >
              <Settings />
            </Protected>
          }
        />
        <Route
          path="/subscription"
          element={
            <Protected
              isAllowed={isUserSignIn && !isOnboarding}
              redirectTo={get_redirect_uri()}
            >
              <Pricing />
            </Protected>
          }
        />
        <Route
          path="/redirect"
          element={
            <Protected isAllowed={isUserSignIn} redirectTo={get_redirect_uri()}>
              <Redirect />
            </Protected>
          }
        />
        <Route
            path="/wiki"
            element={
              <Protected
                isAllowed={isAllowedNotGuest()}
              redirectTo={get_redirect_uri()}
            >
              <WikiPage />
            </Protected>
          }
        />
        <Route path="*" element={<Navigate to="/checklist" replace />} />
      </Routes>
    </div>
  );
}

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ContextProvider>
        <AppLogic />
      </ContextProvider>
    </ThemeProvider>
  );
};

export default App;
