import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { iNotification, useNotifications } from "@/contexts/NotificationContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";

import { Context } from "@/contexts/ContextProvider";
import ErrorIcon from "@mui/icons-material/Error";
import SyncIcon from "@mui/icons-material/Sync";
import { TaskState } from "@/types/common";
import { sync_repo } from "@/services/Blar/Repo_graph";

type TableSyncIconProps = {
  repoId: string;
  initialState: TaskState;
};


const TableSyncIcon = ({ repoId, initialState }: TableSyncIconProps) => {
  const [loading, setLoading] = useState(false);
  const { showMessage } = useContext(Context);
  const [state, setState] = useState<TaskState>(initialState);
  const [hover, setHover] = useState(false);
  const { subscribeToNotifications, unsubscribeFromNotifications } = useNotifications()


  const handleSync = async () => {
    setLoading(true);
    try {
      await sync_repo({
        repo_id: repoId,
      });
      showMessage("success", "Repo scheduled to sync");
      setState("pending");
    } catch (error) {
      showMessage("error", "Error syncing Repo");
    } finally {
      setLoading(false);
    }
  };

  const handleRepoSyncedNotification = useCallback((notification: iNotification) => {

    if (notification.data.action !== "repo_synced" || notification.data.repo_id !== repoId) return;

    switch (notification.data.task_state) {
      case "completed":
        showMessage("success", "Repo synced successfully");
        setState("completed");
        setHover(false);
        break;
      case "failed":
        showMessage("error", "Error syncing Repo");
        setState("failed");
        setHover(false);
        break;
    }
  }, [showMessage]);

  useEffect(() => {
    subscribeToNotifications(handleRepoSyncedNotification);
    return () => {
      unsubscribeFromNotifications(handleRepoSyncedNotification);
    };
  }, [])


  useEffect(() => {
    if (state === "in_progress" || state === "pending") {
      setLoading(true);
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, repoId]);

  return (
    <>
      {loading ? (
        <CircularProgress size={20} sx={{
          justifyContent: "center",
          alignItems: "center",
        }} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center" }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {state === "failed" ? (
              <Tooltip title="Something failed while syncing the repo. Please try again.">
                <IconButton onClick={handleSync}>
                  {hover ? (
                    <SyncIcon fontSize="small" sx={{ color: "#1a7f64" }} />
                  ) : (
                    <ErrorIcon fontSize="small" sx={{ color: "#cc3300" }} />
                  )}
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton onClick={handleSync} >
                <SyncIcon fontSize="small" sx={{ color: "#1a7f64" }} />
              </IconButton>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TableSyncIcon;
