import * as React from "react";
import { Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FileNode } from "@/pages/Chat/components/types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { debounce } from "@mui/material/utils";
import { CircularProgress, Tooltip } from "@mui/material";
import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { useCallback, useContext, useState, useRef, useEffect } from "react";
import { search_node } from "@/services/Blar/Repo_graph";
import { AxiosResponse } from "axios";
import { Context } from "@/contexts/ContextProvider";

interface SearchInputProps {
  options: FileNode[];
  loading?: boolean;
  setSelectedNodes: (nodes: FileNode[]) => void;
  selectedRepo?: string;
  selectedNodes: FileNode[];
  containerRef?: React.RefObject<HTMLElement>;
  containerWidth: number;
}

const SearchInput: React.FC<SearchInputProps> = ({
  options,
  loading = false,
  setSelectedNodes,
  selectedRepo,
  selectedNodes,
  containerWidth
}) => {
  const [nodeOptions, setNodeOptions] = useState<FileNode[]>([]);
  const [loadingNodes, setLoadingNodes] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const { showMessage } = useContext(Context);

  

  const handleSearchChange = useCallback(async (value: string) => {
    if (!value) {
      setNodeOptions([]);
      return;
    }

    try {
      setLoadingNodes(true);
      const response: AxiosResponse = await search_node(value);
      const options = response.data as FileNode[];
      setNodeOptions(options);
    } catch (error) {
      console.error(error);
      showMessage("error", "Failed to search");
    } finally {
      setLoadingNodes(false);
    }
  }, [showMessage]);

  const getSearchWidth = () => {
    if (!isFocused) {
      return '200px'; // Width when not focused
    }
    return containerWidth > 600 ? '50%' : '100%';
  };

  return (
      <Box 
        sx={{ 
          width: getSearchWidth(),
          transition: 'all 0.3s ease-in-out',
          minWidth: '200px',
          margin: 0
        }}
      >
        <MenuSearch
          onChange={handleSearchChange}
          options={nodeOptions}
          placeholder="CODE SEARCH"
          loading={loadingNodes}
          setSelectedNode={(node) => {
            if (node && !selectedNodes.some(n => n.node_id === node.node_id)) {
              const nodeWithValue = {
                ...node,
                value: node.name || formatPath(node.path),
                source: 'search',
                id: node.node_id
              };
              setSelectedNodes([...selectedNodes, nodeWithValue]);
            }
            setInputValue("");
            setIsFocused(false); // Reset focus when a selection is made
          }}
          selectedNodes={selectedNodes}
          selectedRepo={selectedRepo}
          inputValue={inputValue}
          setInputValue={setInputValue}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            if (!inputValue) {
              setIsFocused(false);
            }
          }}
        />
      </Box>
  );
};

export default SearchInput;

const SEARCH_TOOLTIP =
  "You can search parts of your code (functions, classes, files) to take it as reference to write your wiki";

const formatPath = (path: string | undefined) => {
  if (!path) return '';
  const segments = path.split(/[/]/);
  const remainingSegments = segments.slice(4);
  return remainingSegments.join("/");
};

interface MenuSearchProps {
  onChange: (value: string) => void;
  placeholder?: string;
  loading?: boolean;
  options: FileNode[];
  setSelectedNode: (node: FileNode) => void;
  selectedRepo?: string;
  selectedNodes: FileNode[];
  inputValue: string;
  setInputValue: (value: string) => void;
  onFocus: () => void;
  onBlur: () => void;
}

const MenuSearch: React.FC<MenuSearchProps> = ({
  onChange,
  options = [],
  placeholder,
  loading = false,
  setSelectedNode,
  selectedRepo,
  selectedNodes,
  inputValue,
  setInputValue,
  onFocus,
  onBlur
}) => {
  const fetch = React.useMemo(
    () =>
      debounce((input: string | null) => {
        onChange(input || "");
      }, 400),
    [onChange]
  );

  React.useEffect(() => {
    fetch(inputValue);
  }, [inputValue, fetch]);

  const getIcon = (type: string[]) => {
    if (type.includes("CLASS")) {
      return <DataObjectOutlinedIcon sx={{ color: "#FFFFFF" }} />;
    } else if (type.includes("FILE")) {
      return <InsertDriveFileOutlinedIcon sx={{ color: "#FFFFFF" }} />;
    } else if (type.includes("FUNCTION")) {
      return (
        <img
          src="/icons/function-icon-white.png"
          alt="Function Icon"
          style={{ color: "#FFFFFF", width: "22px", height: "22px" }}
        />
      );
    } else {
      return <InsertDriveFileOutlinedIcon sx={{ color: "#FFFFFF" }} />;
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 0}}>
      <Autocomplete
        getOptionLabel={(option) =>
          typeof option === "string" ? option : formatPath(option?.path)
        }
        sx={{
          "& fieldset": { borderRadius: "10px" },
          "& .MuiAutocomplete-popupIndicator": { transform: "none" },
          flexGrow: 1,
          marginBottom: 0,
        }}
        size="small"
        isOptionEqualToValue={(option, value) =>
          value ? option.node_id === value.node_id : false
        }
        loading={loading}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        value={null}
        inputValue={inputValue}
        noOptionsText={"No results found"}
        popupIcon={<SearchIcon />}
        onChange={(event: any, newValue: FileNode | null) => {
          if (newValue) {
            setSelectedNode(newValue);
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            fullWidth
            onFocus={onFocus}
            onBlur={onBlur}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            color="primary"
            size="small"
          />
        )}
        renderOption={(props, option) => {
          // @ts-ignore
          const { key, ...optionProps } = props;
          const matches = match(formatPath(option.path), inputValue);
          const parts = parse(formatPath(option.path), matches);
          return (
            <li key={option.node_id} {...optionProps}>
              <Tooltip
                title={formatPath(option.path)}
                placement="top-start"
                enterNextDelay={800}
              >
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item sx={{ marginRight: "10px" }}>
                    {getIcon(option.type)}
                  </Grid>
                  <Grid
                    item
                    sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                  >
                    {parts.map((part, index) => (
                      <Box
                        key={`${option.node_id}-part-${index}`}
                        component="span"
                        sx={{ 
                          fontWeight: part.highlight ? 1000 : "regular",
                          fontSize: "14px"
                        }}
                      >
                        {part.text}
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Tooltip>
            </li>
          );
        }}
      />
      <Box
        display={"flex"}
        height={"100%"}
        justifyContent={"center"}
        padding={1}
        alignItems={"center"}
      >
        <Tooltip title={SEARCH_TOOLTIP}>
          <InfoIcon fontSize="small" color="primary" />
        </Tooltip>
      </Box>
    </Box>
  );
};
