import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';
import { useChecklistContext } from '../contexts/ChecklistContext';
import { useCheckTestsContext } from '../contexts/CheckTestsContext';
import { mark_step_status_as_completed_manually, mark_step_status_as_failed_manually } from '@/services/Blar/Checklist';
export type TestStatusType = 'completed' | 'failed'

interface TestStatusIconProps {
  status: TestStatusType;
  step_id: string;
}

interface ConfirmDialogState {
  open: boolean;
  action: () => Promise<void>;
  title: string;
  message: string;
}

const TestStatusIcon = ({ status, step_id }: TestStatusIconProps) => {
  const { selectedRepo } = useChecklistContext();
  const { checkData, setCheckData } = useCheckTestsContext();
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogState | null>(null);

  const updateStepStatus = async (newStatus: TestStatusType, response: any) => {
    if (!checkData) return;
    
    const updatedTests = checkData.tests.map(test => 
      test.id === step_id ? {
        ...test,
        status: response.status,
        status_details: response.status_details,
        manually_completed: response.manually_completed,
        updated_at: response.updated_at
      } : test
    );
    
    setCheckData({ ...checkData, tests: updatedTests });
  };

  const markStepStatusAsCompleted = async () => {
    try {
      setLoading(true);
      const response = await mark_step_status_as_completed_manually(step_id, selectedRepo!.repo_id);
      updateStepStatus('completed', response.data);
    } finally {
      setLoading(false);
      setConfirmDialog(null);
    }
  }

  const markStepStatusAsFailed = async () => {
    try {
      setLoading(true);
      const response = await mark_step_status_as_failed_manually(step_id, selectedRepo!.repo_id);
      updateStepStatus('failed', response.data);
    } finally {
      setLoading(false);
      setConfirmDialog(null);
    }
  }

  const handleStatusClick = (action: () => Promise<void>, isCompletingManually: boolean) => {
    setConfirmDialog({
      open: true,
      action,
      title: isCompletingManually ? "Manually Complete Test" : "Mark Test as Failed",
      message: isCompletingManually 
        ? "By manually completing this test, you're overriding the automated check. You can still run the automated check later or rerun all tests if needed."
        : "Are you sure you want to mark this test as failed? You can rerun the test later or check it again if needed."
    });
  };

  const statusConfig = {
    completed: (
      <IconButton 
        onClick={() => handleStatusClick(markStepStatusAsFailed, false)} 
        size="small"
        disabled={loading}
      >
        {loading ? <CircularProgress size={20} /> : <CheckBoxOutlinedIcon />}
      </IconButton>
    ),
    failed: (
      <IconButton 
        onClick={() => handleStatusClick(markStepStatusAsCompleted, true)} 
        size="small"
        disabled={loading}
      >
        {loading ? <CircularProgress size={20} /> : <CheckBoxOutlineBlankOutlinedIcon />}
      </IconButton>
    ),
  };

  return (
    <>
      {statusConfig[status as keyof typeof statusConfig]}
      <Dialog
        open={confirmDialog?.open || false}
        onClose={() => setConfirmDialog(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{confirmDialog?.title}</DialogTitle>
        <DialogContent>
          <Typography>{confirmDialog?.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog(null)}>Cancel</Button>
          <Button 
            onClick={async () => {
              if (confirmDialog?.action) {
                await confirmDialog.action();
              }
            }} 
            variant="contained" 
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TestStatusIcon;
