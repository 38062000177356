import { Box, Button } from "@mui/material";
import { Test } from "../types";
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useCheckTestsContext } from "../contexts/CheckTestsContext";
interface RemediateButtonProps {
  test: Test;
}

export default function RemediateButton({ test }: RemediateButtonProps) {
  const { selectedTest, setSelectedTest } = useCheckTestsContext();

  const handleRemediate = (test: Test) => {
    setSelectedTest(test);
  }

  return (
    test.status === 'completed' ? (
    <Box display="flex" width="100%" alignItems="center" justifyContent="flex-end">
      <Button 
        variant="outlined" 
        color="success"
        sx={{
          fontFamily: "Inter",
          fontWeight: 400,
          fontSize: 16,
          paddingY: 0,
          paddingX: 1,
          margin: 0,
          display: "flex",
          alignItems: "center",
          borderColor: "success.main",
          opacity: 1,
          gap: 1,
          '&:hover': {
            cursor: "default",
            borderColor: "success.main",
            opacity: 1,
            gap: 1,
            '&:hover': {
              cursor: "default",
              borderColor: "success.main",
              opacity: 1
            }
          }}}
        >
          Done
          <CheckCircleIcon sx={{ height: 20, width: 20 }} />
        </Button>
      </Box>
    ) : (
      <Box display="flex" width="100%" alignItems="center" justifyContent="flex-end">
        <Button
          variant="contained"
          sx={{
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: 16,
            paddingY: 0,
            paddingX: 1,
            backgroundColor: "#4593F8",
            color: "white",
            margin: 0,
            display: "flex",
            alignItems: "center",
            gap: 1,
            '&:hover': {
              backgroundColor: "#4593F8",
              opacity: 0.8
            }
          }}
          onClick={() => handleRemediate(test)}
        >
          Remediate
          <BuildIcon sx={{ height: 20, width: 20 }} />
        </Button>
      </Box>
    )
  )
}