import React, { useRef, useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import FileStructure from "./components/WikiFileStructure";
import FileEditor from "./components/FileEditor";
import TemplateGallery from "./components/TemplateGallery";
import ResizableLayout from "@/components/Layout/ResizebleLayout";
import { WikiProvider, useWiki } from "./context/WikiContext";

const WikiPageContent: React.FC = () => {
  const { selectedNode, inputFileContent, isFileChatView } = useWiki();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const sidebarContent = <FileStructure />;

  const renderMainContent = () => {
    if (!selectedNode) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography variant="h6">
            Select a file to view its contents
          </Typography>
        </Box>
      );
    }

    if (selectedNode.type === 'folder') {
      return <TemplateGallery />;
    }

    if (selectedNode.type === 'file' && inputFileContent !== null) {
      return <FileEditor key={selectedNode.id} />;
    }

    return null;
  };

  const mainContent = renderMainContent();

  return (
    <Box ref={containerRef} width="100%" height="100%">
      <ResizableLayout
        sidebarContent={sidebarContent}
        mainContent={mainContent}
        initialWidth={400}
        minWidth={300}
        maxWidth={isFileChatView ? Math.floor(containerWidth / 2) : 500}
        forceWidth={isFileChatView ? Math.floor(containerWidth / 2) : 400}
      />
    </Box>
  );
};

const WikiPage: React.FC = () => {
  return (
    <WikiProvider>
      <WikiPageContent />
    </WikiProvider>
  );
};

export default WikiPage;
