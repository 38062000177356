import { 
  differenceInMinutes, 
  differenceInHours, 
  differenceInDays 
} from 'date-fns'

const formatRepoName = (name: string, capitalize: boolean = true) => {
  const repoName = name.split('/').pop() || '';
  return capitalize ? repoName.charAt(0).toUpperCase() + repoName.slice(1) : repoName;
}

const calculateLastRefresh = (lastRefresh: Date) => {
  const now = new Date()
  
  const minutesDiff = differenceInMinutes(now, lastRefresh)
  if (minutesDiff < 60) {
    return minutesDiff === 1 ? '1 minute ago' : `${minutesDiff} minutes ago`
  }
  
  const hoursDiff = differenceInHours(now, lastRefresh)
  if (hoursDiff < 24) {
    return hoursDiff === 1 ? '1 hour ago' : `${hoursDiff} hours ago`
  }
  
  const daysDiff = differenceInDays(now, lastRefresh)
  return daysDiff === 1 ? '1 day ago' : `${daysDiff} days ago`
}

const dataToChecklistItem = (data: any) => {
  return data.map((item: any) => {
    return {
      id: item.criteria__id,
      status: item.status,
      name: item.criteria__name,
      description: item.criteria__description,
      impact: item.criteria__impact,
      timeToFix: item.time_to_fix,
      percentage: item.completed_steps / item.total_steps * 100 || 0
    }
  })
}

const dataToCriteriaData = (data: any, id: string) => {
  return {
    id: id,
    last_refresh: data.criteria_status.updated_at,
    name: data.name,
    description: data.description,
    tests: data.steps,
  }
}

const formatDuration = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  let result = '';

  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''} `;
  }

  if (remainingMinutes > 0) {
    result += `${remainingMinutes} min${remainingMinutes > 1 ? 's' : ''} `;
  }

  if (hours === 0 && remainingMinutes === 0) {
    result += `${remainingSeconds} sec${remainingSeconds > 1 ? 's' : ''}`;
  }

  return result.trim();
}

export { formatRepoName, calculateLastRefresh, dataToChecklistItem, formatDuration, dataToCriteriaData };
