import { blarClient } from "./Client";


export const get_subscription = async () => {
  return blarClient.request("get", `/subscriptions/`);
};

export const create_subscription = async () => {
  return blarClient.request("post", `/subscriptions/`);
};

export const get_subscription_price = async () => {
  return blarClient.request("get", `/subscriptions/price/`);
}