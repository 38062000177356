import { Box, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ChecklistTestSkeleton = () => {
  const theme = useTheme();
  
  return (
    <Box width="100%" margin="auto" height="100%" textAlign="left" padding={8} display="flex" flexDirection="column" gap={8}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Skeleton variant="text" width="40%" height={48} />
        <Skeleton variant="text" width="70%" height={24} />
        <Skeleton variant="text" width="60%" height={24} />
      </Box>
      <Box>
        <Skeleton variant="text" width="15%" height={32} sx={{ marginBottom: 2 }} />
        <Box marginX={2}>
          <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ marginBottom: 2 }}>
            <Skeleton variant="text" width={200} height={24} />
          </Box>
          {[...Array(5)].map((_, index) => (
            <Box key={index} sx={{ 
              display: 'flex',
              gap: 2,
              padding: '12px',
              borderBottom: `1px solid ${theme.palette.divider}`,
              alignItems: 'center'
            }}>
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width="70%" height={24} />
              <Box flexGrow={1} />
              <Skeleton variant="rounded" width={100} height={32} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ChecklistTestSkeleton; 