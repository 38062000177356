import React from "react";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { tomorrowNightBright as codeStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";
import CopyButton from "@/components/Buttons/CopyButton";
import { useWiki } from "../context/WikiContext";

interface WikiProps {
  children?: React.ReactNode;
  node?: any;
  classes: any;
}

const WikiCanvas = ({ children, classes }: WikiProps) => {
  const { updateEditorContent } = useWiki();
  
  return (
    <Box
      sx={{
        position: 'relative',
        mx: 0,
        backgroundColor: 'background.paper',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        maxHeight: '300px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: (theme) => theme.palette.divider,
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
      }}
    >
      <Box
        sx={{
          position: 'sticky',
          top: 8,
          right: 8,
          zIndex: 1,
          display: 'inline-flex',
          float: 'right'
        }}
      >
        <Button
          variant="outlined"
          size="small"
          sx={{
            backgroundColor: 'background.paper',
            borderColor: 'divider',
            '&:hover': {
              backgroundColor: 'action.hover',
              borderColor: 'divider',
            },
            textTransform: 'none',
            fontSize: '0.75rem',
            py: 0.5,
            px: 1,
            margin: 0
          }}
          onClick={() => updateEditorContent(children as string)}
        >
          to Editor
        </Button>
      </Box>
      <Box sx={{ position: 'relative', p: 3 }}>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            h1: ({children}) => <Typography variant="h4" gutterBottom component="h1">{children}</Typography>,
            h2: ({children}) => <Typography variant="h5" gutterBottom component="h2">{children}</Typography>,
            h3: ({children}) => <Typography variant="h6" gutterBottom component="h3">{children}</Typography>,
            h4: ({children}) => <Typography variant="subtitle1" gutterBottom component="h4">{children}</Typography>,
            h5: ({children}) => <Typography variant="subtitle2" gutterBottom component="h5">{children}</Typography>,
            h6: ({children}) => <Typography variant="subtitle2" gutterBottom component="h6">{children}</Typography>,
            p: ({children}) => <Typography paragraph component="p">{children}</Typography>,
            ul: ({children}) => (
              <Box component="ul" sx={{ pl: 2, my: 1, listStyleType: 'disc' }}>
                {children}
              </Box>
            ),
            li: ({children}) => (
              <Box 
                component="li" 
                sx={{ 
                  display: 'list-item',
                  pl: 1,
                  '& > p': { 
                    m: 0 
                  }
                }}
              >
                <Typography component="span">{children}</Typography>
              </Box>
            ),
            strong: ({children}) => <Box component="strong" sx={{ fontWeight: 'bold' }}>{children}</Box>,
            em: ({children}) => <Box component="em" sx={{ fontStyle: 'italic' }}>{children}</Box>,
            code(props) {
              const { children, className, ...rest } = props;
              const match = /language-(\w+)/.exec(className || "");
              return match ? (
                <div style={{ position: "relative" }}>
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, "")}
                    language={match[1]}
                    style={codeStyle}
                    customStyle={{
                      borderRadius: "20px",
                      padding: "20px",
                      fontSize: "14px",
                    }}
                  />
                  <Box className={classes.copyButton}>
                    <CopyButton
                      copyValue={String(children).replace(/\n$/, "")}
                      backgroundColor="black"
                      size="small"
                      sx={{
                        height: "20px",
                      }}
                    />
                  </Box>
                </div>
              ) : (
                <code
                  {...rest}
                  className={className}
                  style={{
                    textOverflow: "ellipsis",
                    wordBreak: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {children}
                </code>
              );
            },
          }}
        >
          {children as string}
        </ReactMarkdown>
      </Box>
    </Box>
  );
};

export default WikiCanvas; 