import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';

interface ResizableLayoutProps {
  sidebarContent: React.ReactNode;
  mainContent: React.ReactNode;
  initialWidth?: number;
  minWidth?: number;
  maxWidth?: number;
  forceWidth?: number;
}

const ResizableLayout: React.FC<ResizableLayoutProps> = ({
  sidebarContent,
  mainContent,
  initialWidth = 400,
  minWidth = 300,
  maxWidth = 500,
  forceWidth,
}) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(initialWidth);
  const [isForceResizing, setIsForceResizing] = useState(false);

  useEffect(() => {
    if (forceWidth !== undefined) {
      setIsForceResizing(true);
      setSidebarWidth(forceWidth);
    }
  }, [forceWidth]);

  const startResizing = useCallback(() => {
    setIsResizing(true);
    setIsForceResizing(false);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isResizing && sidebarRef.current) {
        setIsForceResizing(false);
        const newWidth = mouseMoveEvent.clientX - sidebarRef.current.getBoundingClientRect().left;
        setSidebarWidth(Math.max(minWidth, Math.min(maxWidth, newWidth)));
      }
    },
    [isResizing, minWidth, maxWidth]
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <Box 
      display="flex" 
      height="100vh" 
      overflow="hidden"
    >
      <Box
        ref={sidebarRef}
        sx={{
          width: sidebarWidth,
          position: 'relative',
          borderRight: '1px solid #666',
          flexShrink: 0,
          boxSizing: 'border-box',
          transition: isForceResizing ? 'width 0.3s ease-in-out' : 'none',
        }}
      >
        <Box 
          sx={{
            height: '100%',
            overflow: 'auto',
            boxSizing: 'border-box',
          }}
        >
          {sidebarContent}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: -4,
            top: 0,
            bottom: 0,
            width: 8,
            cursor: 'ew-resize',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
            '&:active': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
            zIndex: 1000,
          }}
          onMouseDown={startResizing}
        />
      </Box>
      <Box 
        flex={1}
        overflow="auto"
        sx={{ 
          boxSizing: 'border-box',
        }}
      >
        {mainContent}
      </Box>
    </Box>
  );
};

export default ResizableLayout;
