import React from "react";
import { Button, Typography, Box } from "@mui/material";

interface ReadyToGoProps {
  continueOnboarding: () => void;
}

const ReadyToGoComponent: React.FC<ReadyToGoProps> = ({
  continueOnboarding,
}) => {
  return (
    <Box display="flex" flexDirection='column' sx={{ height:'100%'}} textAlign="center" justifyContent="center">
      <Typography
        variant="h3"
        align="center"
        fontSize={50}
        color={""}
        sx={{
          lineHeight: "40px",
          marginBottom: "20px",
        }}
      >
        You are all set!
      </Typography>
      <Box>
        <img src="/logos/BlueLogo.svg" alt="Blar" />
      </Box>
      <Button
        variant="contained"
        color="secondary"
        style={{ fontSize: "20px" }}
        onClick={continueOnboarding}
      >
        Explore Blar's plataform!
      </Button>
    </Box>
  );
};

export default ReadyToGoComponent;
