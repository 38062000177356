import {
  CreateRepoParams,
  SyncRepoParams,
} from "@/pages/Settings/components/Repos/types";
import {
  GetNodeOutput,
  QueryNodesListOutput,
} from "../../interfaces/IBackendOutputs";
import { blarClient } from "./Client";

export const list_node = async (): Promise<QueryNodesListOutput> => {
  const query = await blarClient.request(
    "get",
    `/repo_graph/nodes/node_level?group=true`
  );
  return query?.data;
};

export const get_node = async (
  nodeId: string,
  environment: string = "main"
): Promise<GetNodeOutput> => {
  const query = await blarClient.request(
    "get",
    `/repo_graph/nodes/${nodeId}`,
    null,
    { environment }
  );
  return query?.data;
};

export const get_max_level = async () => {
  return blarClient.request("get", `/repo_graph/repos/max_level`);
};

export const list_repos = async () => {
  return blarClient.request("get", `/repo_graph/repos/list`);
};

export const create_repo = async (params: CreateRepoParams) => {
  return blarClient.request("post", "/repo_graph/repos/create/", params);
};

export const sync_repo = async (params: SyncRepoParams) => {
  return blarClient.request("post", "/repo_graph/repos/sync/", params);
};

export const delete_repo = async (repo_id: string) => {
  return blarClient.request("post", "/repo_graph/repos/delete/", { repo_id });
};

export const checkRepoState = async (repo_id: string) => {
  return blarClient.request("post", "/repo_graph/repos/state/", { repo_id });
};

export const search_node = async (
  input_search: string,
  environment: string = "main"
) => {
  return blarClient.request("get", `/repo_graph/nodes/search`, null, {
    input_search,
    environment,
  });
};

export const get_root_hierarchy = async (environment: string = "main") => {
  return blarClient.request("get", `/repo_graph/nodes/hierarchy`, null, {
    environment,
  });
};

export const get_node_hierarchy = async (
  node_id: string,
  environment: string = "main"
) => {
  return blarClient.request(
    "get",
    `/repo_graph/nodes/hierarchy_node/${node_id}`,
    null,
    { environment }
  );
};

export const checkSyncingRepos = async () => {
  return blarClient.request("get", "/repo_graph/repos/syncing/");
};
