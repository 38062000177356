import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'; 
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import CircularProgress from '@mui/material/CircularProgress';

export type StatusType = 'failed' | 'pending' | 'completed' | 'in_progress';

interface StatusIconProps {
  status: StatusType;
}

const StatusIcon = ({ status }: StatusIconProps) => {
  const statusConfig = {
    completed: <CheckBoxOutlinedIcon sx={{ color: "blarSuccess.main" }}/>,
    pending: <ReportProblemOutlinedIcon sx={{ color: "blarHigh.main" }} />,
    failed: <ReportOutlinedIcon sx={{ color: "blarCritical.main" }} />,
    in_progress: <CircularProgress sx={{ color: "blarHigh.main" }} size={'1.7em'}/>,
  };

  return statusConfig[status as keyof typeof statusConfig];
};

export default StatusIcon;
