import { useContext, useRef, useEffect, useMemo, useState } from "react";
import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css";
import "@/pages/Chat/components/Chat/styles/ChatInput.css";
import { CircularProgress, Box, Paper, Button, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { Context } from "@/contexts/ContextProvider";
import { useTagify } from "@/pages/Chat/context/TagifyContext";
import { useWiki } from "../context/WikiContext";
import debounce from "lodash.debounce";
import { search_node } from "@/services/Blar/Repo_graph";
import DOMPurify from "dompurify";
import SearchInput from "@/pages/Wiki/components/SearchInput";


// Tagify Configuration
const TAGIFY_SETTINGS = {
  mode: "mix",
  pattern: /\\/,
  enforceWhitelist: false,
  keepInvalidTags: true,
  placeholder: "Message Blar or \\select a code node",
  mixTagsInterpolator: ["<blar-tag>", "</blar-tag>"],
  trim: false,
  editTags: false,
  delimiters: " ",
  addTagOnBlur: false,
  onChangeAfterBlur: false,
  addTagOn: ["enter", "tab"],
  dropdown: {
    enabled: 1,
    mapValueTo: "value",
    highlightFirst: true,
    className: "chat-input",
    placeAbove: true,
    position: "all",
  }
};

const extractUniqueNodeIds = (tags) => {
  return Array.from(
    new Map(
      tags
        .filter(tag => tag.tagType === "node")
        .map(tag => [tag.id, tag.id])
    ).keys()
  );
};

const extractUniqueTags = (tags) => {
  // Use Map to ensure uniqueness by ID, keeping only the last occurrence of each tag
  return Array.from(
    new Map(
      tags.map(tag => [tag.id, tag])
    ).values()
  );
}

const buildFinalMessage = (inputMessage, wikiContent) => {
  if (!wikiContent?.trim()) return inputMessage;
  return `<wiki>${wikiContent}</wiki>\n\n${inputMessage}`;
};

const getWikiContent = (editorRef, inputFileContent) => {
  return editorRef.current?.getMarkdown() || inputFileContent;
};

const mapNodesToTagify = (nodes) => {
  return nodes.map((node) => ({
    value: node.name,
    id: node.node_id,
    path: node.path?.replace("temp.repos.", ""),
    type: node.type,
    tagType: "node",
  }));
};

const suggestionItemTemplate = (item, tagify) => {
  const isSelected = tagify?.isTagDuplicate(item.id);
  const attributes = tagify?.getAttributes(item);
  const itemClasses = [
    tagify?.settings.classNames.dropdownItem,
    isSelected ? tagify?.settings.classNames.dropdownItemSelected : "",
    item.class || "",
  ]
    .join(" ")
    .trim();

  const sanitize = (value) => {
    const clean = DOMPurify.sanitize(value, {
      ALLOWED_TAGS: [],
      ALLOWED_ATTR: [],
    });

    return clean;
  };

  let iconHtml = '';
  if (item.tagType === "node") {
    const iconSrc = item.type.includes("CLASS")
      ? "/icons/data_object.png"
      : item.type.includes("FILE")
      ? "/icons/insert_drive.png"
      : item.type.includes("FUNCTION")
      ? "/icons/function-icon-white.png"
      : "/icons/file-icon.png";
    iconHtml = `<div class='tagify__dropdown__item__avatar-wrap' key="icon-${item.id}">
        <img onerror="this.style.visibility='hidden'" src="${iconSrc}" alt="Node Icon" style="width: 22px; height: 22px; margin-right: 8px;">
      </div>`;
  }

  return `
    <div 
      ${sanitize(attributes) || ""}
      class="${sanitize(itemClasses)}"
      tabindex="0"
      role="option"
      key="${item.id || 'suggestion-' + Math.random()}"
    >
      ${iconHtml}
      <strong>${sanitize(item.value)}</strong>
      ${item.tagType === "node" ? `<br><span key="path-${item.id}">${sanitize(formatNodePath(item.path))}</span>` : ''}
    </div>
  `;
};

const formatNodePath = (path) => {
  if (!path) return '';
  const segments = path.split(/[/]/);
  const remainingSegments = segments.slice(4);
  return remainingSegments.join("/");
};

const TagifyComponent = ({
  sendMessage,
  loading,
  messages
}) => {
  const theme = useTheme();
  const inputRef = useRef(null);
  const tagifyRef = useTagify();
  const { showMessage } = useContext(Context);
  const { 
    inputFileContent, 
    wikiHasUnsyncedChanges,
    setWikiHasUnsyncedChanges,
    editorRef,
    setOutputFileContent
  } = useWiki();

  // 1) Maintain a list of selected "nodes" for rendering next to the CODE SEARCH button
  const [selectedNodes, setSelectedNodes] = useState([]);
  // Track nodes added via tagify separately
  const [tagifyNodes, setTagifyNodes] = useState([]);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  // Update selectedNodes whenever tagifyNodes changes
  useEffect(() => {
    setSelectedNodes(prev => {
      const searchNodes = prev.filter(node => node.source === 'search');
      return [...searchNodes, ...tagifyNodes];
    });
  }, [tagifyNodes]);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        const parentWidth = containerRef.current.parentElement?.clientWidth || 0;
        setContainerWidth(parentWidth);
      }
    };

    // Initial width update
    updateWidth();

    // Create a ResizeObserver to monitor container size changes
    const resizeObserver = new ResizeObserver(updateWidth);
    
    // Observe both the container and its parent
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
      if (containerRef.current.parentElement) {
        resizeObserver.observe(containerRef.current.parentElement);
      }
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const debouncedFetchNodes = useMemo(
    () =>
      debounce(async (value) => {
        if (!tagifyRef.current) return;
        const tagify = tagifyRef.current;

        try {
          tagify.loading(true);
          tagify.whitelist = [];
          
          if (!value) return;
          
          const response = await search_node(value);
          tagify.whitelist = mapNodesToTagify(response.data);
          tagify.dropdown.show();
        } catch (error) {
          showMessage("error", "Failed to fetch code nodes");
        } finally {
          tagify.loading(false);
          tagify.dropdown.toggle(true);
        }
      }, 300),
    [showMessage]
  );

  useEffect(() => {
    return () => {
      debouncedFetchNodes.cancel();
    };
  }, [debouncedFetchNodes]);

  useEffect(() => {
    if (inputRef.current && !tagifyRef.current) {
      const tagify = new Tagify(inputRef.current, {
        ...TAGIFY_SETTINGS,
        templates: {
          dropdownItem: (item) => suggestionItemTemplate(item, tagify),
        },
      });

      // Disables input if needed
      tagify.setDisabled(loading);
      tagifyRef.current = tagify;

      // Define event handlers inside useEffect to avoid dependency issues
      const handleInput = async (e) => {
        const { prefix, value } = e.detail;
        if (prefix && prefix.trim() === "\\" && value.length > 1) {
          await debouncedFetchNodes(value);
        }
      };

      // Create a bound version of the keydown handler
      function handleKeydown(e) {
        if (
          e.detail.event.key === "Enter" &&
          !e.detail.event.shiftKey &&
          !this.state.tag &&
          (this.suggestedListItems.length === 0 || !this.state.dropdown.visible)
        ) {
          e.preventDefault();
          onMessageSend();
        }
      }

      const onAdd = (e) => {
        if (e.detail?.data?.tagType === "node") {
          setTagifyNodes(prev => [...prev, e.detail.data]);
        }
      }

      const onRemove = (e) => {
        if (e.detail?.data?.tagType === "node") {
          setTagifyNodes(prev => prev.filter((node) => node.id !== e.detail.data.id));
        }
      }

      const onChange = (e) => {
        const allTags = tagify.getCleanValue();
        const uniqueTags = extractUniqueTags(allTags);
        const onlyNodeTags = uniqueTags.filter(t => t.tagType === "node");
        setTagifyNodes(onlyNodeTags);
      }

      // Tagify event listeners:
      tagify
        .on("input", handleInput)
        .on("keydown", handleKeydown.bind(tagify))
        .on("add", onAdd)
        .on("remove", onRemove)
        .on("change", onChange)

      return () => {
        tagify.destroy();
        tagifyRef.current = null;
      };
    }
  }, []);

  // Effect to handle loading state changes
  useEffect(() => {
    if (tagifyRef.current) {
      tagifyRef.current.setDisabled(loading);
    }
  }, [loading]);

  const onMessageSend = async () => {
    const tagify = tagifyRef.current;
    if (!tagify) return;

    const rawInput = tagify.DOM.input.textContent;

    if (rawInput) {
      try {
        const currentWikiContent = getWikiContent(editorRef, inputFileContent);
        const shouldIncludeWiki = handleWikiContent(currentWikiContent);
        
        const finalMessage = shouldIncludeWiki 
          ? buildFinalMessage(rawInput, currentWikiContent)
          : rawInput;

        // Use all selectedNodes (both from search and tagify)
        const nodeIds = selectedNodes.map(node => node.id);
        
        await sendMessage(
          finalMessage,
          nodeIds,
          tagify.state.lastOriginalValueReported
        );
        
        // Clear both sources of nodes
        setSelectedNodes([]);
        setTagifyNodes([]);
        tagify.removeAllTags();
      } catch (error) {
        showMessage("error", "Failed to send message");
      }
    }
  };

  const handleWikiContent = (content) => {
    if ((messages.length === 0 || wikiHasUnsyncedChanges) && content?.trim()) {
      setOutputFileContent(content);
      setWikiHasUnsyncedChanges(false);
      return true;
    }
    return false;
  };

  const handleRemoveNode = (nodeId) => {
    // Remove from both sources
    setSelectedNodes((prev) => prev.filter((node) => node.id !== nodeId));
    setTagifyNodes((prev) => prev.filter((node) => node.id !== nodeId));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box ref={containerRef} sx={{ display: "flex", width: "100%", alignItems: "center", flexWrap: "wrap", gap: 1, marginBottom: 0}}>
        <SearchInput
          placeholder="Search for a code node"
          value={selectedNodes}
          setSelectedNodes={setSelectedNodes}
          selectedNodes={selectedNodes}
          containerRef={containerRef}
          containerWidth={containerWidth}
        />

        {selectedNodes.map((node) => (
          <Box
            key={node.id || node.node_id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              margin: 0,
            }}
          >
            {node.source === 'search' ? (
              <Button
                variant="outlined"
                endIcon={
                  <CloseIcon
                    fontSize="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveNode(node.id || node.node_id);
                    }}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        opacity: 0.8
                      }
                    }}
                  />
                }
                sx={{
                  borderRadius: "10px",
                  textTransform: "none",
                  borderColor: theme.palette.divider,
                  color: "inherit",
                  minWidth: "fit-content",
                  height: "40px",
                  margin: 0,
                  paddingRight: 1,
                  '& .MuiButton-endIcon': {
                    marginLeft: 0.5,
                    marginRight: 0
                  }
                }}
              >
                <code>{node.value}</code>
              </Button>
            ) : (
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                  textTransform: "none",
                  borderColor: theme.palette.divider,
                  color: "inherit",
                  minWidth: "fit-content",
                  height: "40px",
                  margin: 0,
                }}
              >
                <code>{node.value}</code>
              </Button>
            )}
          </Box>
        ))}
      </Box>

      <Paper
        sx={{
          borderColor: theme.palette.divider,
          display: "flex",
          alignItems: "center",
          borderRadius: "10px",
          padding: "4px 8px",
          marginTop: 1
        }}
      >
        <textarea
          className="chat-input"
          style={{ 
            width: "100%", 
            maxHeight: "20vh", 
            overflowY: "auto"
          }}
          ref={inputRef}
          onChange={() => {}}
          name="mix"
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <img
            src="/icons/send.svg"
            alt="Send"
            id="send-button"
            onClick={onMessageSend}
            style={{
              cursor: "pointer",
              height: "20px",
              marginLeft: "10px",
            }}
          />
        )}
      </Paper>
    </Box>
  );
};

export default TagifyComponent;
