import { Box, Typography } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { useChecklistContext } from "../contexts/ChecklistContext";
import TableWithActions from "@/components/Tables/TableWithActions";
import { useEffect, useState } from "react";
import StatusIcon, { StatusType } from "./StatusIcon";
import ImpactChip, { ImpactType } from "./ImpactChip";
import { ChecklistItem } from "../types";
import { useCheckTestsContext, CheckTestsContextProvider } from "../contexts/CheckTestsContext";
import { list_repo_checklist_items } from "@/services/Blar/Checklist";
import { dataToChecklistItem, formatDuration } from "../helpers";

const Checklist = () => {
  const { selectedRepo } = useChecklistContext();
  const { handleSelectCheck } = useCheckTestsContext();
  const [checklistItems, setChecklistItems] = useState<ChecklistItem[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchChecklist = async () => {
    const checklistResult = await list_repo_checklist_items(selectedRepo!.repo_id);
    console.log("checklistResult", checklistResult.data);
    setChecklistItems(dataToChecklistItem(checklistResult.data));
  }

  useEffect(() => {
    if (selectedRepo) {
      fetchChecklist();
    }
  }, [selectedRepo]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedRepo) {
        fetchChecklist();
      }
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const formatTableRows = (items: ChecklistItem[]) => {
    return items.map(item => ({
      Status: { type: "react" as const, content: <StatusIcon status={item.status as StatusType} /> },
      Name: { type: "string" as const, content: item.name },
      Progress: { type: "number" as const, content: item.percentage },
      "Time to Fix": { type: "string" as const, content: formatDuration(item.timeToFix) },
      Impact: { type: "react" as const, content: <ImpactChip impact={item.impact as ImpactType} /> },
    }));
  };

  const defaultTitles = ["Status", "Name", "Progress", "Time to Fix", "Impact"];


  // TODO: Remove this
  if (!selectedRepo) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="h6" fontFamily="Ubuntu Sans Mono"></Typography>
      </Box>
    );
  }

  return (
    <Box px="20px" marginTop={16}>
      {/* <Typography variant="h5" fontFamily="Ubuntu Sans Mono">{selectedRepo.name}</Typography> */}
      <TableWithActions 
        tableRows={formatTableRows(checklistItems)}
        defaultTitles={defaultTitles}
        loading={loading}
        onClick={(index) => handleSelectCheck(checklistItems[index].id)}
        maxHeight="calc(100vh - 200px)"
      />
    </Box>
  );
};

const Repochecklist = () => {
  return (
    <CheckTestsContextProvider>
      <Routes>
        <Route path="/:repoName" element={<Checklist />} />
        <Route path="/" element={<Checklist />} />
      </Routes>
    </CheckTestsContextProvider>
  );
};

export default Repochecklist;