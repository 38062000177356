import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, useTheme, CircularProgress } from "@mui/material";
import { useWiki } from "../context/WikiContext";
import { getTemplates, getTemplateContent } from "@/services/Blar/Wiki";

interface Template {
  name: string;
  path: string;
}

const TemplateGallery: React.FC = () => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState(true);
  const [creatingFile, setCreatingFile] = useState<string | null>(null);
  const theme = useTheme();
  const { selectedNode, handleCreateFile } = useWiki();

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await getTemplates();
        setTemplates(response);
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  const formatTemplateName = (name: string) => {
    return name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const handleTemplateClick = async (template: Template) => {
    if (creatingFile) return;
    
    try {
      setCreatingFile(template.path);
      const response = await getTemplateContent(template.path);
      await handleCreateFile(formatTemplateName(template.name), selectedNode!.id, response.content);
    } catch (error) {
      console.error('Error fetching template content:', error);
    } finally {
      setCreatingFile(null);
    }
  };

  const handleCreateFromScratch = async () => {
    if (creatingFile) return;
    
    try {
      setCreatingFile('scratch');
      await handleCreateFile("Style Guide", selectedNode!.id, "");
    } catch (error) {
      console.error('Error creating empty file:', error);
    } finally {
      setCreatingFile(null);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography>Loading templates...</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={4}>
      <Paper
        sx={{
          borderColor: theme.palette.divider,
          width: '100%',
          maxWidth: '800px',
          padding: 3,
        }}
      >
        <Box sx={{ mb: 4, textAlign: 'left' }}>
          <Typography variant="h4" sx={{ fontWeight: 500, mb: 1 }}>
            Let's get started for {selectedNode!.name}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
            Select a template to get started or create your own by scratch.
          </Typography>
        </Box>

        <Box 
          id="scrollableDiv" 
          sx={{ 
            overflow: 'auto', 
            maxHeight: 'calc(100vh - 300px)',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gap: 2,
          }}
        >
          <Paper
            key="scratch"
            elevation={0}
            onClick={handleCreateFromScratch}
            sx={{
              p: 2,
              cursor: creatingFile ? 'not-allowed' : 'pointer',
              transition: 'all 0.2s',
              border: '1px',
              borderColor: theme.palette.primary.main,
              borderRadius: 2,
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: creatingFile ? 'transparent' : 'action.hover',
              },
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              opacity: creatingFile ? 0.7 : 1,
            }}
          >
            <Box
              sx={{
                width: 40,
                height: 40,
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {creatingFile === 'scratch' ? (
                <CircularProgress size={24} />
              ) : (
                <img src="/icons/edit_square.svg" alt="Create" style={{ width: 24, height: 24 }} />
              )}
            </Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 500, color: theme.palette.primary.main }}>
              Create your own
            </Typography>
          </Paper>
          {templates.map((template) => (
            <Paper
              key={template.path}
              elevation={0}
              onClick={() => handleTemplateClick(template)}
              sx={{
                p: 2,
                cursor: creatingFile ? 'not-allowed' : 'pointer',
                transition: 'all 0.2s',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 2,
                '&:hover': {
                  borderColor: creatingFile ? theme.palette.divider : theme.palette.primary.main,
                  backgroundColor: creatingFile ? 'transparent' : 'action.hover',
                },
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                opacity: creatingFile ? 0.7 : 1,
              }}
            >
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'action.selected',
                }}
              >
                {creatingFile === template.path ? (
                  <CircularProgress size={24} />
                ) : (
                  <img src="/icons/insert_drive.png" alt="Template" style={{ width: 24, height: 24 }} />
                )}
              </Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {formatTemplateName(template.name)}
              </Typography>
            </Paper>
          ))}
        </Box>
      </Paper>
    </Box>
  );
};

export default TemplateGallery; 