import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { ChecklistContextProvider, useChecklistContext } from "./contexts/ChecklistContext";
import TableWithActions from "@/components/Tables/TableWithActions";
import { Check, Test } from "./types";
import SyncIcon from '@mui/icons-material/Sync';
import CircularProgress from '@mui/material/CircularProgress';
import TestStatusIcon, { TestStatusType } from "./components/TestStatusIcon";
import RemediateButton from "./components/RemediateButton";
import RemediateDrawer from "./components/RemediateDrawer";
import { calculateLastRefresh } from "./helpers";
import { CheckTestsContextProvider, useCheckTestsContext } from "./contexts/CheckTestsContext";
import ReactMarkdown from 'react-markdown'
import { get_criteria_data, run_steps_for_criteria_status, get_criteria_status_by_id } from "@/services/Blar/Checklist";
import { dataToCriteriaData } from "./helpers";
import { useParams } from "react-router-dom";
import ChecklistTestSkeleton from "./components/skeletons/ChecklistTestSkeleton";

const ChecklistPageContent: React.FC = () => {
  const [formattedRefreshTime, setFormattedRefreshTime] = useState<string>('')
  const { selectedRepo } = useChecklistContext();
  const { checkId } = useParams();
  const { checkData, setCheckData } = useCheckTestsContext();
  const [isLoading, setIsLoading] = useState(true);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);

  const getCheckData = async () => {
    const response = await get_criteria_data(selectedRepo!.repo_id, checkId as string)
    return dataToCriteriaData(response.data, checkId as string)
  }

  const getCriteriaStatus = async () => {
    if (!selectedRepo) return;
    const response = await get_criteria_status_by_id(selectedRepo!.repo_id, checkId as string)
    if (response.data.status === 'in_progress') {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }

  useEffect(() => {
    if (!selectedRepo) return;
    setIsLoading(true);
    getCheckData().then(data => {
      if (data) setCheckData(data);
      setIsLoading(false);
    });
  }, [selectedRepo, setCheckData]);

  useEffect(() => {
    if (checkData) {
      setFormattedRefreshTime(calculateLastRefresh(checkData.last_refresh))
    }
  }, [checkData])

  useEffect(() => {
    if (!selectedRepo) return;
    const interval = setInterval(() => {
      getCriteriaStatus()
    }, 1000);
    return () => clearInterval(interval);
  }, [selectedRepo]);

  const formatTableRows = (tests: Test[] | undefined) => {
    if (!tests) return [];
    return tests.map(test => ({
      "Status": { type: "react" as const, content: <TestStatusIcon status={test.status as TestStatusType} step_id={test.id} /> },
      "Task": { type: "string" as const, content: test.name },
      "": {
        type: "react" as const,
        content: <RemediateButton test={test} />
      },
    }));
  };

  if (isLoading) {
    return <ChecklistTestSkeleton />;
  }

  return (
    <Box width="100%" margin="auto" height="100%" textAlign="left" padding={8} display="flex" flexDirection="column" gap={8}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography fontFamily="Ubuntu Sans Mono" variant="h4" fontWeight={500}>{checkData?.name}</Typography>
        <ReactMarkdown>
          {checkData?.description || ''}
        </ReactMarkdown>
      </Box>
      <Box>
        <Typography fontFamily="Ubuntu Sans Mono" variant="h5">Tests</Typography>
        <Box marginX={2}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Typography fontFamily="Inter" variant="body1">Last refreshed {formattedRefreshTime}</Typography>
            <IconButton onClick={async () => {
              setDisabledButton(true);
              try {
                await run_steps_for_criteria_status(checkId as string, selectedRepo!.repo_id);
              } catch (error) {
                console.error('Error running steps:', error);
                setDisabledButton(false);
              }
            }} disabled={disabledButton}>
              {disabledButton ? <CircularProgress size={"1em"} /> : <SyncIcon />}
            </IconButton>
          </Box>
          <TableWithActions
            tableRows={formatTableRows(checkData?.tests)}
            defaultTitles={["Status", "Task", ""]}
            loading={!checkData}
          />
        </Box>
      </Box>
      <RemediateDrawer />
    </Box>
  );
};

const ChecklistTestPage: React.FC = () => {
  return (
    <ChecklistContextProvider>
      <CheckTestsContextProvider>
        <ChecklistPageContent />
      </CheckTestsContextProvider>
    </ChecklistContextProvider>
  );
};

export default ChecklistTestPage;