import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Repo } from "@/pages/Settings/components/Repos/types";
import RepoProgress from "./RepoProgress";
import SearchBar from "./SearchBar"
import { useEffect, useState } from "react";
import { list_repo_checklist_percentage } from "@/services/Blar/Checklist";
import { useChecklistContext } from "../contexts/ChecklistContext";
import { useParams, useNavigate } from "react-router-dom";
import { formatRepoName } from "../helpers";
import RepoProgressSkeleton from "./skeletons/RepoProgressSkeleton";

export interface RepoChecklistPercentage {
  repo_id: string;
  repo_status_percentage: number;
  name: string;
}

const ReposList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [repoList, setRepoList] = useState<RepoChecklistPercentage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedRepo, setSelectedRepo } = useChecklistContext();
  const repoNameFromUrl = useParams().repoName;
  
  async function fetchReposChecklistPercentageData() {
    const reposResult = (await list_repo_checklist_percentage());
    const repos = reposResult.data;
    return repos;
  }

  const updateSelectedRepo = (repo: RepoChecklistPercentage) => {
    setSelectedRepo(repo);
    const repoNameForUrl = formatRepoName(repo.name, false).toLowerCase().replace(/_/g, '-');
    navigate(`/checklist/${repoNameForUrl}`);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const repos = await fetchReposChecklistPercentageData();
        setRepoList(repos);
        
        if (!selectedRepo) {
          if (repoNameFromUrl) {
            const repo = repos.find((r: Repo) => {
              const repoNameWithoutOrg = formatRepoName(r.name, false).toLowerCase();
              const urlRepoName = repoNameFromUrl.toLowerCase();
              return repoNameWithoutOrg === urlRepoName ||
                     repoNameWithoutOrg.replace(/_/g, '-') === urlRepoName;
            });
            if (repo) {
              updateSelectedRepo(repo);
            }
          } else if (repos.length > 0) {
            // Select the first repo if no repo is selected and no repo is specified in URL
            updateSelectedRepo(repos[0]);
          }
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Box sx={{ padding: "20px", textAlign: "left", display: "flex", flexDirection: "column", gap: "10px" }}>
      <Typography variant="h5" sx={{ fontWeight: "bold" }} fontFamily={"Ubuntu Sans Mono"} marginBottom={2}>Repos</Typography>
      <SearchBar />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {isLoading ? (
          <>
            <RepoProgressSkeleton />
            <RepoProgressSkeleton />
            <RepoProgressSkeleton />
          </>
        ) : (
          repoList.map((repo) => (
            <RepoProgress key={repo.name} repo={repo} />
          ))
        )}
      </Box>
    </Box>
  );
};

export default ReposList;
