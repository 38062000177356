import React, { useContext, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import ChecklistIcon from '@mui/icons-material/Checklist';
import TuneIcon from "@mui/icons-material/Tune";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import CustomToggleButton from "../Buttons/ToggleButton";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";

import AnchorMenu from "../AnchorMenu/AnchorMenu";
import MoreMenu from "./MoreMenu";
import { useTheme } from "@mui/material/styles";
import { Context } from "@/contexts/ContextProvider";
import useIntegrationSources from "./IntegrationsList" ;

type SidebarProps = {
  style?: React.CSSProperties;
};

function getInitialSelected(location: any) {
  const queryParams = new URLSearchParams(location.search);
  const triggerType = queryParams.get("triggerType");
  if (location.pathname === "/chat") {
    return triggerType || "";
  }
  if (location.pathname === "/settings")
    return "settings";
  return "";
}

const Sidebar: React.FC<SidebarProps> = ({ style }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const sources = useIntegrationSources(); 
  const [selected, setSelected] = React.useState(getInitialSelected(location));
  const [settingsAnchorEl, setSettingsAnchorEl] =
    React.useState<null | HTMLElement>(null);

  useEffect(() => {
    setSelected(getInitialSelected(location));
  }, [location]);


  const handleClickChecklist = (
    event: React.MouseEvent<HTMLElement>,
    value: string // Assuming value is a string
  ) => {
    setSelected("checklist");
    navigate("/checklist");
  };

  const handleClickFilters = (
    event: React.MouseEvent<HTMLElement>,
    value: string // Assuming value is a string
  ) => {
    setSelected(value);
    if (value === "") {
      navigate("/chat");
      return;
    }
    navigate(`/chat?triggerType=${encodeURIComponent(value)}`);
  };

  const handleClickWiki = (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ) => {
    setSelected(value);

    navigate("/wiki");
  };

  const handleClickSettingsMenu = (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 50,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 50,
          boxSizing: "border-box",
          borderRadius: 0,
          backgroundColor: "blarBlack",
          borderRight: "1px solid",
          borderColor: theme.palette.divider,
        },
        ...style,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 50,
        }}
      >
        <Box
          component="img"
          src="/logos/BlarIconWhite.svg"
          alt="Logo"
          sx={{ height: 25, cursor: "pointer" }}
          onClick={() => navigate("/chat")}
        />
      </Box>

      <Box
        mt={4}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "20px",
          flexGrow: 1,
        }}
      >
        <Stack
          spacing={2}
          direction="column"
          useFlexGap
          flexWrap="wrap"
          alignItems="center"
        >
          <CustomToggleButton
            value="checklist"
            selected={selected === "checklist"}
            handleClick={handleClickChecklist}
            tooltip="Lets review your tech debt"
          >
            <ChecklistIcon
              sx={{ width: 20, height: 20, color: "primary" }}
            />
          </CustomToggleButton>
          {/* <CustomToggleButton
            value=""
            selected={selected === ""}
            handleClick={handleClickFilters}
            tooltip="All the chats assign to you"
          >
            <InboxOutlinedIcon
              sx={{ width: 20, height: 20, color: "primary" }}
            />
          </CustomToggleButton>
          <CustomToggleButton
            value="pullrequest"
            selected={selected === "pullrequest"}
            handleClick={handleClickFilters}
            tooltip="All the chats made by Github Pull Requests"
          >
            <img
              src="/integrations/github-mark-white.svg"
              alt="Logo"
              style={{ width: 17, height: 15 }}
            />
          </CustomToggleButton>
          <CustomToggleButton
            value="sentry"
            selected={selected === "sentry"}
            handleClick={handleClickFilters}
            tooltip="All the chats made by Sentry"
            hidden={ !sources.includes("sentry") }
          >
            <img
              src="/integrations/SentryIcon.svg"
              alt="Logo"
              style={{ width: 17, height: 15 }}
            />
          </CustomToggleButton>
          <CustomToggleButton
            value="baseuser"
            selected={selected === "baseuser"}
            handleClick={handleClickFilters}
            tooltip="All the chats made by you"
  
          >
            <AccountCircleOutlinedIcon
              sx={{ width: 20, height: 20, color: "primary" }}
            />
          </CustomToggleButton> */}
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mb: 1,
        }}
      >
        <Stack
          spacing={2}
          direction="column"
          useFlexGap
          flexWrap="wrap"
          alignItems="center"
        >
          <CustomToggleButton
            value="wiki"
            selected={selected === "wiki"}
            handleClick={handleClickWiki}
          >
            <MenuBookOutlinedIcon
              sx={{ width: 20, height: 20, color: "primary" }}
            />
          </CustomToggleButton>
          <CustomToggleButton
            value="settings"
            selected={Boolean(settingsAnchorEl) || selected === "settings"}
            handleClick={handleClickSettingsMenu}
          >
            <TuneIcon sx={{ width: 20, height: 20, color: "primary" }} />
          </CustomToggleButton>
        </Stack>
      </Box>
      <AnchorMenu
        title="More"
        anchorEl={settingsAnchorEl}
        setAnchorEl={setSettingsAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ width: 200, marginLeft: 2 }}
      >
        <MoreMenu setSettingsAnchorEl={setSettingsAnchorEl} />
      </AnchorMenu>
    </Drawer>
  );
};

export default Sidebar;
