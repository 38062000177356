import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomToggleButton from '@/components/Buttons/ToggleButton';
import { Edit } from '@mui/icons-material';

const WikiAgentDescription = () => {
  const theme = useTheme();
  
  return (
      <Paper
        sx={{
          borderColor: theme.palette.divider,
          maxWidth: "90%",
          padding: 3,
        }}
      >
        <Box display="flex" flexDirection="row">
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              mb={2}
              alignItems="center"
            >
              <CustomToggleButton
                handleClick={() => {}}
                value=""
                selected={false}
              >
                <Edit />
              </CustomToggleButton>
              <Typography variant="body1" textAlign="start" ml={2}>
                Wiki Writer
              </Typography>
            </Box>
            <Box ml={2}>
              <Typography variant="body2" textAlign="start" mb={2}>
                1. Type \ to search for any file/class/function by name in your code.
              </Typography>
              <Typography variant="body2" textAlign="start" mb={2}>
                2. Tell Blar what you want to write or improve in this wiki.
              </Typography>
              <Typography variant="body2" textAlign="start" mb={2}>
                3. Let Blar write your documentation. You can edit it in the right panel.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
  );
};

export default WikiAgentDescription; 