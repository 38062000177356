import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatRepoName } from "../helpers";
import { Check, Test } from "../types";
import { useChecklistContext } from "./ChecklistContext";

interface CheckTestsContextType {
  selectedTest: Test | null;
  setSelectedTest: (test: Test | null) => void;
  handleSelectCheck: (checkId: string) => void;
  checkData: Check | null;
  setCheckData: (data: Check | null) => void;
}

const CheckTestsContext = createContext<CheckTestsContextType>({
  selectedTest: null,
  setSelectedTest: () => {},
  handleSelectCheck: () => {},
  checkData: null,
  setCheckData: () => {}
});

export const useCheckTestsContext = () => useContext(CheckTestsContext);

export const CheckTestsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { selectedRepo } = useChecklistContext();
  const [selectedTest, setSelectedTest] = useState<Test | null>(null);
  const [checkData, setCheckData] = useState<Check | null>(null);

  const handleSelectCheck = (checkId: string) => {
    navigate(`/checklist/${formatRepoName(selectedRepo?.name || '', false)}/check/${checkId}`);
  };

  return (
    <CheckTestsContext.Provider 
      value={{ 
        selectedTest, 
        setSelectedTest, 
        handleSelectCheck,
        checkData,
        setCheckData
      }}
    >
      {children}
    </CheckTestsContext.Provider>
  );
};
