import React, { useEffect, useState } from 'react';
import { Drawer, Box, Typography, IconButton, Tabs, Tab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCheckTestsContext } from '../contexts/CheckTestsContext';
import RemediateSteps from '../components/RemediateSteps';
import { get_remediate_step } from '@/services/Blar/Checklist';

interface RemediateStep {
  id: string;
  name: string;
  content: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`remediation-tabpanel-${index}`}
      aria-labelledby={`remediation-tab-${index}`}
      {...other}
      sx={{ 
        height: 'calc(100% - 48px)', // Subtract tabs height
        overflow: 'hidden'
      }}
    >
      {value === index && (
        <Box 
          sx={{ 
            p: 2, 
            height: '100%',
            overflow: 'auto'
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

const RemediateDrawer: React.FC = () => {
  const { selectedTest, setSelectedTest } = useCheckTestsContext();
  const [tabValue, setTabValue] = useState(0);
  const [remediateStep, setRemediateStep] = useState<RemediateStep | null>(null);
  const handleClose = () => {
    setSelectedTest(null);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const fetchRemediateStep = async (testId: string) => {
    const response = await get_remediate_step(testId);
    return response.data
  };

  useEffect(() => {
    if (selectedTest) {
      fetchRemediateStep(selectedTest.id).then(data => {
        setRemediateStep(data);
      });
    }
  }, [selectedTest]);

  return (
    <Drawer
      anchor="right"
      open={selectedTest !== null}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: {
            xs: '100%',
            sm: '60%'
          },
          minWidth: 300,
          borderLeft: '1px solid #FFFFFF4D',
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          }
        }
      }}
    >
      {selectedTest && (
        <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
          <Box p={2} flexShrink={0}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <Typography variant="h5" fontWeight={500} fontFamily="Ubuntu Sans Mono">
                How to Remediate
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            
            <Typography variant="body1" mb={3}>
              {selectedTest.name}
            </Typography>
          </Box>

          <Box sx={{ borderBottom: 1, borderColor: 'divider', flexShrink: 0 }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="remediation tabs">
              <Tab label="Steps" />
            </Tabs>
          </Box>

          <TabPanel value={tabValue} index={0}>
            <RemediateSteps remediateSteps={remediateStep?.content as string} />
          </TabPanel>
        </Box>
      )}
    </Drawer>
  );
};

export default RemediateDrawer;
