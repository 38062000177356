import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import { DataType, TablePaginationOptions, TableRowData } from "./types";
import { LinearProgress, Link } from "@mui/material";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

interface StyledTableRowProps {
  onClick?: () => void;
}

const StyledTableRow = styled(TableRow)<StyledTableRowProps>(({ theme, onClick }) => ({
  height: "50px",
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  ...(onClick && {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.default,
    }
  })
}));

const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
  height: "50px",
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  textAlign: "left",
  fontFamily: "Ubuntu Sans Mono",
  borderColor: "#FFFFFF4D",
  backgroundColor: theme.palette.background.paper,
  position: "sticky",
  top: 0,
  zIndex: 1,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: "left",
  fontFamily: "Ubuntu Sans Mono",
  borderColor: "#FFFFFF4D",
}));

const formatDate = (value: DataType) => {
  const dateFormat = value.options?.dateFormat || "dd MMMM yyyy";
  const dateContent = value.content as string;
  return format(new Date(dateContent), dateFormat);
};

const formatters: { [key in DataType["type"]]: (value: any) => any } = {
  string: (value) => value.content,
  number: (value) => Number.isInteger(value.content) ? value.content.toString() : value.content.toFixed(2),
  date: (value) => formatDate(value),
  boolean: (value) =>
    value.content ? (
      <CheckCircleIcon sx={{ color: "#1a7f64" }} />
    ) : (
      <CloseIcon sx={{ color: "#ef4146" }} />
    ),
  hidden: () => null,
  url: (value) => (
    <Link href={value.content} target="_blank" rel="noopener noreferrer">
      {value.content}
    </Link>
  ),
  react: (value) => value.content,
};

type TableWithActionsProps = {
  tableRows: TableRowData[];
  defaultTitles: string[];
  loading: boolean;
  onDelete?: (index: number) => void;
  onEdit?: (index: number) => void;
  onClick?: (index: number) => void;
  tablePagination?: TablePaginationOptions;
  maxHeight?: string | number;
};

const TableWithActions: React.FC<TableWithActionsProps> = ({
  tableRows,
  defaultTitles,
  loading,
  onDelete,
  onEdit,
  onClick,
  tablePagination,
  maxHeight = '400px',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowIndexToDelete, setRowIndexToDelete] = useState<number | null>(null);
  const theme = useTheme();

  const tableTitles = tableRows[0] ? Object.keys(tableRows[0]) : defaultTitles;
  const visibleTitles = tableTitles.filter(
    (title) => tableRows[0]?.[title]?.type !== "hidden"
  );

  const handleDeleteClick = (index: number) => {
    setRowIndexToDelete(index);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (rowIndexToDelete !== null && onDelete) {
      onDelete(rowIndexToDelete);
      setIsModalOpen(false);
    }
  };

  const handleRowClick = (index: number) => {
    if (onClick) {
      onClick(index);
    }
  };

  return (
    <>
      <TableContainer 
        component={Paper} 
        sx={{ 
          padding: "0", 
          maxHeight: maxHeight,
          overflowY: 'auto', 
          border: '1px solid #FFFFFF4D',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
          <TableHead>
            <StyledTableHeadRow>
              {visibleTitles.map((title) => (
                <StyledTableHeadCell key={title}>
                  {title}
                </StyledTableHeadCell>
              ))}
              {onDelete && <StyledTableHeadCell align="right"></StyledTableHeadCell>}
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {loading && (
              <StyledTableRow>
                <StyledTableCell colSpan={visibleTitles.length + 1}>
                  <LinearProgress />
                </StyledTableCell>
              </StyledTableRow>
            )}
            {!loading &&
              tableRows.map((row, index) => (
                <StyledTableRow
                  theme={theme}
                  key={index}
                  onClick={onClick ? () => handleRowClick(index) : undefined}
                >
                  {tableTitles.map(
                    (title) =>
                      row[title].type !== "hidden" && (
                        <StyledTableCell key={title}>
                          {formatters[row[title].type]
                            ? formatters[row[title].type](row[title])
                            : row[title].content}
                        </StyledTableCell>
                      )
                  )}
                  {onDelete && (
                    <StyledTableCell align="right">
                      <IconButton onClick={() => handleDeleteClick(index)}>
                        <DeleteIcon fontSize="small" sx={{ color: "#ef4146" }} />
                      </IconButton>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {tablePagination && (
        <TablePagination
          component="div"
          count={tablePagination.count}
          rowsPerPageOptions={[10, 20, 30]}
          page={tablePagination.page}
          rowsPerPage={tablePagination.rowsPerPage}
          onPageChange={tablePagination.handlePagination}
          onRowsPerPageChange={tablePagination.handleChangeRowsPerPage}
        />
      )}
      <ConfirmDeleteModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default TableWithActions;
