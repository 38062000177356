import { TableRowData } from "@/components/Tables/types";
import { Repo, RepoPaginatedResponse } from "./types";
import TableSyncIcon from "./components/TableSyncIcon";
import BranchBoxes from "./components/BranchBoxes";
import { TaskState } from "@/types/common";

export function convertReposToRow(data: RepoPaginatedResponse): TableRowData[] {
  return data.results.map((repo: Repo) => ({
    _id: { content: repo.repo_id, type: "hidden" },
    Name: { content: repo.name, type: "string" },
    "GitHub Url": {
      content: repo.url,
      type: "url",
    },
    "Tracked Branches": {
      content: <BranchBoxes mainBranch={repo.main_branch} developmentBranch={repo.development_branch} />,
      type: "react"
    },
    "Last Sync": {
      content: new Date(repo.last_sync),
      type: "date",
      options: { dateFormat: "dd MMMM yyyy HH:mm:SS" },
    },
    "Sync Now": {
      content: (
        <TableSyncIcon
          repoId={repo.repo_id}
          initialState={repo.task_state as TaskState}
        />
      ),
      type: "react",
    },
  }));
}
