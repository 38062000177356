import React, { createContext, useContext, useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { FileNode } from '../types';
import {
  getFolderContents,
  getFileContent,
  updateFile,
  createFolder,
  createFile,
  updateFolder,
  deleteFolder,
  deleteFile,
} from '@/services/Blar/Wiki';
import { Context as AppContext } from '@/contexts/ContextProvider';
import { AgentStep, AgentStepType } from "@/pages/Chat/components/types";
import { w3cwebsocket } from "websocket";
import { createWikiChatRoom } from "@/services/Blar/Wiki";
import { process_message_chunk, get_chat_room_messages } from "@/services/Blar/Chat";
import { connectWebSocket } from "@/services/Websocket/connect";
import { MDXEditorMethods } from "@mdxeditor/editor";

interface WikiContextType {
  structure: FileNode[];
  setStructure: React.Dispatch<React.SetStateAction<FileNode[]>>;
  selectedNode: FileNode | null;
  setSelectedNode: React.Dispatch<React.SetStateAction<FileNode | null>>;
  inputFileContent: string | null;
  setInputFileContent: React.Dispatch<React.SetStateAction<string | null>>;
  outputFileContent: string;
  setOutputFileContent: React.Dispatch<React.SetStateAction<string>>;
  loadingCount: number;
  setLoadingCount: React.Dispatch<React.SetStateAction<number>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  isFileChatView: boolean;
  setIsFileChatView: React.Dispatch<React.SetStateAction<boolean>>;
  handleSaveFile: () => Promise<void>;
  handleRename: (node: FileNode, newName: string) => Promise<void>;
  handleFileClick: (node: FileNode) => Promise<void>;
  parseResponse: (response: any) => FileNode[];
  handleCreateFile: (name: string, parentId: number | null, content: string | null) => Promise<void>;
  handleCreateFolder: (name: string, parentId: number | null) => Promise<void>;
  handleDelete: (node: FileNode) => Promise<void>;
  handleDragStart: (event: React.DragEvent, node: FileNode) => void;
  handleDragOver: (event: React.DragEvent) => void;
  handleDrop: (event: React.DragEvent, parentNode: FileNode) => Promise<void>;
  messages: any[];
  setMessages: React.Dispatch<React.SetStateAction<any[]>>;
  agentStep: AgentStep | null;
  setAgentStep: React.Dispatch<React.SetStateAction<AgentStep | null>>;
  initWebSocket: (fileNodeOrId: FileNode | number) => void;
  handleSendMessage: (input: string, node_ids: string[], frontEndMessage: string | null) => Promise<void>;
  loadingResponse: boolean;
  editorRef: React.RefObject<MDXEditorMethods> | null;
  setEditorRef: (ref: React.RefObject<MDXEditorMethods>) => void;
  updateEditorContent: (content: string) => void;
  wikiHasUnsyncedChanges: boolean;
  setWikiHasUnsyncedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WikiContext = createContext<WikiContextType | null>(null);

interface WikiProviderProps {
  children: React.ReactNode;
}

interface WikiState {
  structure: FileNode[];
  selectedNode: FileNode | null;
  inputFileContent: string | null;
  outputFileContent: string;
  loadingCount: number;
  title: string;
  isFileChatView: boolean;
  messages: any[];
  agentStep: AgentStep | null;
  loadingResponse: boolean;
  editorRef: React.RefObject<MDXEditorMethods> | null;
  wikiHasUnsyncedChanges: boolean;
}

export const WikiProvider: React.FC<WikiProviderProps> = ({ children }) => {
  const [structure, setStructure] = useState<WikiState['structure']>([]);
  const [selectedNode, setSelectedNode] = useState<WikiState['selectedNode']>(null);
  const [inputFileContent, setInputFileContent] = useState<WikiState['inputFileContent']>(null);
  const [outputFileContent, setOutputFileContent] = useState<WikiState['outputFileContent']>('');
  const [loadingCount, setLoadingCount] = useState<WikiState['loadingCount']>(0);
  const [title, setTitle] = useState<WikiState['title']>('');
  const [isFileChatView, setIsFileChatView] = useState<WikiState['isFileChatView']>(false);
  const [messages, setMessages] = useState<WikiState['messages']>([]);
  const [agentStep, setAgentStep] = useState<WikiState['agentStep']>(null);
  const [loadingResponse, setLoadingResponse] = useState<WikiState['loadingResponse']>(false);
  const [editorRef, setEditorRefState] = useState<WikiState['editorRef']>(null);
  const [wikiHasUnsyncedChanges, setWikiHasUnsyncedChanges] = useState<WikiState['wikiHasUnsyncedChanges']>(false);
  const socketRef = useRef<w3cwebsocket | null>(null);
  const closeChatRef = useRef<boolean>(false);
  const { showMessage, access, refresh } = useContext(AppContext);

  const setEditorRef = useCallback((ref: React.RefObject<MDXEditorMethods>) => {
    setEditorRefState(ref);
  }, []);

  const updateEditorContent = useCallback((content: string) => {
    try {
      const trimmedContent = (content || '').trim();
      setOutputFileContent(trimmedContent);
      
      if (editorRef?.current) {
        editorRef.current.setMarkdown(trimmedContent);
        setWikiHasUnsyncedChanges(true);
      }
    } catch (error) {
      console.error('Failed to update editor content:', error);
      showMessage('error', 'Failed to update editor content');
    }
  }, [editorRef, showMessage, setWikiHasUnsyncedChanges]);

  useEffect(() => {
    if (editorRef?.current && outputFileContent !== undefined) {
      try {
        editorRef.current.setMarkdown(outputFileContent);
      } catch (error) {
        console.error('Failed to sync editor content:', error);
        showMessage('error', 'Failed to sync editor content');
      }
    }
  }, [editorRef, outputFileContent, showMessage]);

  const parseResponse = useCallback((response: any): FileNode[] => {
    const folders = response.folders.map((folder: any) => ({
      id: folder.id,
      name: folder.name,
      type: 'folder',
      path: folder.path,
      parent: folder.parent,
      children: [],
    }));

    const files = response.files.map((file: any) => ({
      id: file.id,
      name: file.name,
      type: 'file',
      path: file.path,
      parent: file.folder,
      content: file.content,
    }));

    return [...folders, ...files];
  }, []);

  const handleSaveFile = useCallback(async () => {
    if (!selectedNode || selectedNode.type !== 'file') {
      return;
    }

    setLoadingCount((prev) => prev + 1);
    try {
      const currentContent = editorRef?.current?.getMarkdown?.() ?? outputFileContent;
      if (currentContent === undefined) {
        throw new Error('No content available to save');
      }

      const updatePromises = [
        updateFile(selectedNode.id, {
          id: selectedNode.id,
          name: selectedNode.name,
          content: currentContent,
          folder: selectedNode.parent,
        }),
        new Promise<void>((resolve) => {
          setOutputFileContent(currentContent);
          setInputFileContent(currentContent);
          setTitle(selectedNode.name);
          setWikiHasUnsyncedChanges(false);
          resolve();
        })
      ];

      await Promise.all(updatePromises);
      showMessage('success', 'File saved successfully');
    } catch (error) {
      console.error('Failed to save file:', error);
      showMessage('error', 'Failed to save file');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [selectedNode, outputFileContent, showMessage, setWikiHasUnsyncedChanges, editorRef]);

  const handleRename = useCallback(async (node: FileNode, newName: string) => {
    try {
      setLoadingCount((prev) => prev + 1);
      if (node.type === 'folder') {
        await updateFolder(node.id, {
          name: newName,
          parent: node.parent,
          id: node.id,
          path: node.path,
        });
      } else {
        await updateFile(node.id, {
          name: newName,
          content: node.content || '',
          folder: node.parent,
          id: node.id,
          path: node.path,
        });
      }

      setStructure((prevStructure) => {
        const updateNodeName = (nodes: FileNode[]): FileNode[] => {
          return nodes.map((n) => {
            if (n.id === node.id) {
              return { ...n, name: newName };
            }
            if (n.children) {
              return { ...n, children: updateNodeName(n.children) };
            }
            return n;
          });
        };
        return updateNodeName(prevStructure);
      });

      if (selectedNode && selectedNode.id === node.id) {
        setTitle(newName);
      }

      showMessage('success', 'Renamed successfully');
    } catch (error) {
      showMessage('error', 'Failed to rename');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [selectedNode, showMessage]);

  const handleFileClick = async (node: FileNode) => {
    setSelectedNode(node);
    setAgentStep(null);
    setMessages([]);
    setLoadingResponse(false);
    
    if (node.type === 'file') {
      setInputFileContent(null);
      setOutputFileContent('');
      setLoadingCount((prev) => prev + 1);
      setIsFileChatView(true);
      try {
        const content = await getFileContent(node.id);
        setInputFileContent(content);
        setOutputFileContent(content);
      } finally {
        setLoadingCount((prev) => prev - 1);
      }
    }
    
    initWebSocket(node);
  };

  const handleCreateFile = useCallback(async (
    name: string, 
    parentId: number | null,
    content: string | null = " "
  ) => {
    try {
      setLoadingCount((prev) => prev + 1);
      const newFile = await createFile(name, parentId, content);
      const newFileNode: FileNode = {
        id: newFile.id,
        name: newFile.name,
        type: 'file',
        parent: newFile.folder,
        path: newFile.path,
      };

      setStructure((prev) => {
        const addFileToStructure = (nodes: FileNode[]): [FileNode[], boolean] => {
          let found = false;
          const updatedNodes = nodes.map((node) => {
            if (node.id === parentId) {
              found = true;
              return {
                ...node,
                children: [...(node.children || []), newFileNode],
              };
            }
            if (node.children) {
              const [updatedChildren, childFound] = addFileToStructure(node.children);
              if (childFound) {
                found = true;
                return { ...node, children: updatedChildren };
              }
            }
            return node;
          });
          return [updatedNodes, found];
        };

        const [updatedStructure, found] = addFileToStructure(prev);
        if (!found) {
          return [...prev, newFileNode];
        }
        return updatedStructure;
      });
      
      setSelectedNode(newFileNode);
      handleFileClick(newFileNode);
      
      showMessage('success', 'File created successfully');
    } catch (error) {
      showMessage('error', 'Failed to create file');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [showMessage, handleFileClick]);

  const handleCreateFolder = useCallback(async (name: string, parentId: number | null) => {
    try {
      setLoadingCount((prev) => prev + 1);
      const newFolder = await createFolder(name, parentId);
      const newFolderNode: FileNode = {
        id: newFolder.id,
        name: newFolder.name,
        type: 'folder',
        parent: newFolder.parent,
        path: newFolder.path,
        children: [],
      };

      setStructure((prev) => {
        const addFolderToStructure = (nodes: FileNode[]): [FileNode[], boolean] => {
          let found = false;
          const updatedNodes = nodes.map((node) => {
            if (node.id === parentId) {
              found = true;
              return {
                ...node,
                children: [...(node.children || []), newFolderNode],
              };
            }
            if (node.children) {
              const [updatedChildren, childFound] = addFolderToStructure(node.children);
              if (childFound) {
                found = true;
                return { ...node, children: updatedChildren };
              }
            }
            return node;
          });
          return [updatedNodes, found];
        };

        const [updatedStructure, found] = addFolderToStructure(prev);
        if (!found) {
          return [...prev, newFolderNode];
        }
        return updatedStructure;
      });
      
      showMessage('success', 'Folder created successfully');
    } catch (error) {
      showMessage('error', 'Failed to create folder');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [showMessage]);

  const handleDelete = useCallback(async (node: FileNode) => {
    try {
      setLoadingCount((prev) => prev + 1);
      if (node.type === 'folder') {
        await deleteFolder(node.id);
      } else {
        await deleteFile(node.id);
      }

      setStructure((prevStructure) => {
        const removeNode = (nodes: FileNode[]): FileNode[] => {
          return nodes.filter((n) => {
            // If this is the node to delete, filter it out
            if (n.id === node.id) return false;
            
            // If this node has children, recursively filter them
            if (n.children) {
              n.children = removeNode(n.children);
            }
            return true;
          });
        };

        return removeNode(prevStructure);
      });

      setSelectedNode(null);
      showMessage('success', 'Deleted successfully');
    } catch (error) {
      showMessage('error', 'Failed to delete');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [showMessage]);

  const handleDragStart = useCallback((event: React.DragEvent, node: FileNode) => {
    event.dataTransfer.setData("application/json", JSON.stringify(node));
  }, []);

  const handleDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(async (event: React.DragEvent, parentNode: FileNode) => {
    event.preventDefault();
    const draggedNode = JSON.parse(
      event.dataTransfer.getData("application/json")
    ) as FileNode;
    if (draggedNode.id !== parentNode.id) {
      try {
        setLoadingCount((prev) => prev + 1);
        const updatedNode = { ...draggedNode, parent: parentNode.id };
        if (draggedNode.type === "file") {
          await updateFile(draggedNode.id, {
            name: updatedNode.name,
            content: updatedNode.content || "",
            folder: parentNode.id,
            id: updatedNode.id,
          });
        } else {
          await updateFolder(draggedNode.id, updatedNode);
        }
        setStructure((prev) => {
          const removeOldNode = (nodes: FileNode[]): FileNode[] => {
            return nodes.filter((n) => {
              if (n.id === draggedNode.id) return false;
              if (n.children) {
                n.children = removeOldNode(n.children);
              }
              return true;
            });
          };

          const addToNewParent = (nodes: FileNode[]): FileNode[] => {
            return nodes.map((n) => {
              if (n.id === parentNode.id) {
                return {
                  ...n,
                  children: [...(n.children || []), updatedNode],
                };
              }
              if (n.children) {
                return {
                  ...n,
                  children: addToNewParent(n.children),
                };
              }
              return n;
            });
          };

          const withoutOldNode = removeOldNode(prev);
          return addToNewParent(withoutOldNode);
        });
        showMessage("success", "Moved successfully");
      } catch (error) {
        showMessage("error", "Failed to move item");
      } finally {
        setLoadingCount((prev) => prev - 1);
      }
    }
  }, [showMessage]);

  const initWebSocket = useCallback((fileNodeOrId: FileNode | number) => {
    // Close existing WebSocket connection
    if (socketRef.current) {
      closeChatRef.current = true;
      socketRef.current.close(1000, "Switching wiki files");
      socketRef.current = null;
    }

    const isNewFile = typeof fileNodeOrId === 'number' 
      ? selectedNode?.id !== fileNodeOrId
      : selectedNode?.id !== fileNodeOrId.id;

    const createChatRoom = async () => {
      try {
        const fileNodeId = typeof fileNodeOrId === 'number' ? fileNodeOrId : fileNodeOrId.id;
        const response = await createWikiChatRoom(fileNodeId);
        const chatRoomId = response.id;
        
        // Fetch messages after getting/creating chat room
        try {
          const chatRoomResponse = await get_chat_room_messages(chatRoomId);
          if (chatRoomResponse.messages && isNewFile) {
            setMessages(chatRoomResponse.messages.map(message => ({
              ...message,
              user_thumbs_up: false,
              user_thumbs_down: false,
            })));
          }
          
          if (chatRoomResponse.task_state === "in_progress") {
            setLoadingCount((prev) => prev + 1);
          }
        } catch (error) {
          console.error('Error fetching messages:', error);
          showMessage("error", "Error fetching chat messages");
        }
        
        const chatUrl = `${process.env.REACT_APP_WS_URL}/chat/${chatRoomId}/?token=${access}&refresh_token=${refresh}`;
        
        const onMessage = (event: any) => {
          try {
            const json_data = process_message_chunk(event.data);
            if (json_data.type === "info") {
              if (json_data.message?.message === "Agent Started") {
                setLoadingResponse(true);
              } else if (json_data.message?.message === "Agent Finished") {
                setLoadingResponse(false);
              }
            }

            if (json_data.type === AgentStepType.Function) {
              if (json_data.response && json_data.call === "get_code_by_id") {
                const hasCorrectFormat =
                  json_data.response.node_name && json_data.response.file_path;
                if (hasCorrectFormat) {
                  setAgentStep(json_data);
                }
              }
            }
            if (
              json_data.type === AgentStepType.AIM ||
              json_data.type === AgentStepType.User
            ) {
              if (json_data.message) {
                if (json_data.type === AgentStepType.AIM && typeof json_data.message.message === 'string') {
                  const content = json_data.message.message;
                  const wikiMatches = content.match(/<wiki>([\s\S]*?)<\/wiki>/g);
                  if (wikiMatches) {
                    wikiMatches.forEach((match: string) => {
                      const wikiContent = match.replace(/<\/?wiki>/g, '');
                      updateEditorContent(wikiContent);
                    });
                  }
                }
                setMessages((prevMessages) => [
                  ...prevMessages, 
                  {
                    ...json_data.message,
                    user_thumbs_up: false,
                    user_thumbs_down: false,
                  }
                ]);
                setAgentStep(null);
              }
            }
          } catch (error) {
            setLoadingResponse(false);
            showMessage("error", "Error processing message");
          }
        };

        const onOpen = (socket: w3cwebsocket | null) => {
          closeChatRef.current = false;
          socketRef.current = socket;
          showMessage("success", "Connected to chat");
        };

        const onClose = () => {
          setAgentStep(null);
          setLoadingResponse(false);
          if (!closeChatRef.current) {
            setTimeout(() => {
              if (!closeChatRef.current) {
                createChatRoom();
              }
            }, 3000);
          }
        };

        const onError = () => {
          setLoadingResponse(false);
          showMessage("error", "WebSocket connection error");
          if (socketRef.current) {
            socketRef.current.close();
            socketRef.current = null;
          }
        };

        connectWebSocket(chatUrl, onMessage, onOpen, onError, onClose, closeChatRef);
      } catch (error) {
        console.error(error);
        showMessage("error", "Error creating/getting chat room");
        setLoadingResponse(false);
      }
    };

    createChatRoom();
  }, [access, refresh, showMessage, setLoadingCount]);

  useEffect(() => {
    const fetchRootFolderContents = async () => {
      setLoadingCount((prev) => prev + 1);
      try {
        const response = await getFolderContents(null);
        const parsedStructure = parseResponse(response);
        setStructure(parsedStructure);
      } finally {
        setLoadingCount((prev) => prev - 1);
      }
    };

    fetchRootFolderContents();
  }, [parseResponse]);

  useEffect(() => {
    if (selectedNode && selectedNode.type === 'file') {
      setTitle(selectedNode.name);
    }
  }, [selectedNode]);

  const handleSendMessage = React.useCallback(
    async (input: string, node_ids: string[], frontEndMessage: string | null) => {
      if (input === "") {
        return;
      }

      setLoadingResponse(true);
      setAgentStep(null);

      const params = {
        message: input,
        node_ids: node_ids,
        type: "blar_agent_message",
        front_end_message: frontEndMessage,
      };

      const sendMessageWithRetry = async (retryCount = 0, maxRetries = 3): Promise<void> => {
        try {
          if (!socketRef.current) {
            if (retryCount < maxRetries) {
              if (selectedNode) {
                await initWebSocket(selectedNode);
                // Wait for connection to be established
                await new Promise(resolve => setTimeout(resolve, 1500));
              }
              return sendMessageWithRetry(retryCount + 1, maxRetries);
            }
            throw new Error("WebSocket connection failed after retries");
          }

          if (socketRef.current.readyState === WebSocket.OPEN) {
            // Ensure the socket stays open for response
            const messagePromise = new Promise<void>((resolve, reject) => {
              const timeoutId = setTimeout(() => {
                reject(new Error("Message sending timed out"));
              }, 10000); // 10 second timeout

              const originalOnMessage = socketRef.current!.onmessage;
              
              socketRef.current!.onmessage = (event: any) => {
                clearTimeout(timeoutId);
                // Restore original handler
                if (socketRef.current) {
                  socketRef.current.onmessage = originalOnMessage;
                }
                resolve();
              };
            });

            socketRef.current.send(JSON.stringify(params));
            await messagePromise;
          } else if (socketRef.current.readyState === WebSocket.CONNECTING) {
            await new Promise(resolve => setTimeout(resolve, 1500));
            return sendMessageWithRetry(retryCount, maxRetries);
          } else {
            if (retryCount < maxRetries) {
              socketRef.current = null;
              if (selectedNode) {
                await initWebSocket(selectedNode);
                // Wait for connection to be established
                await new Promise(resolve => setTimeout(resolve, 1500));
              }
              return sendMessageWithRetry(retryCount + 1, maxRetries);
            }
            throw new Error("WebSocket is not in OPEN state after retries");
          }
        } catch (error) {
          if (retryCount < maxRetries) {
            await new Promise(resolve => setTimeout(resolve, 1500));
            return sendMessageWithRetry(retryCount + 1, maxRetries);
          }
          console.error('Error sending message:', error);
          setAgentStep(null);
          setLoadingResponse(false);
          showMessage("error", "Failed to send message. Please try again.");
        }
      };

      await sendMessageWithRetry();
    },
    [selectedNode, showMessage, initWebSocket]
  );

  const value = useMemo(() => ({
    structure,
    setStructure,
    selectedNode,
    setSelectedNode,
    inputFileContent,
    setInputFileContent,
    outputFileContent,
    setOutputFileContent,
    loadingCount,
    setLoadingCount,
    title,
    setTitle,
    isFileChatView,
    setIsFileChatView,
    handleSaveFile,
    handleRename,
    handleFileClick,
    parseResponse,
    handleCreateFile,
    handleCreateFolder,
    handleDelete,
    handleDragStart,
    handleDragOver,
    handleDrop,
    messages,
    setMessages,
    agentStep,
    setAgentStep,
    initWebSocket,
    handleSendMessage,
    loadingResponse,
    editorRef,
    setEditorRef,
    updateEditorContent,
    wikiHasUnsyncedChanges,
    setWikiHasUnsyncedChanges,
  }), [
    structure,
    selectedNode,
    inputFileContent,
    outputFileContent,
    loadingCount,
    title,
    isFileChatView,
    handleSaveFile,
    handleRename,
    handleFileClick,
    parseResponse,
    handleCreateFile,
    handleCreateFolder,
    handleDelete,
    handleDragStart,
    handleDragOver,
    handleDrop,
    messages,
    agentStep,
    initWebSocket,
    handleSendMessage,
    loadingResponse,
    editorRef,
    setEditorRef,
    updateEditorContent,
    wikiHasUnsyncedChanges,
    setWikiHasUnsyncedChanges,
  ]);

  return <WikiContext.Provider value={value}>{children}</WikiContext.Provider>;
};

export const useWiki = () => {
  const context = useContext(WikiContext);
  if (!context) {
    throw new Error('useWiki must be used within a WikiProvider');
  }
  return context;
}; 