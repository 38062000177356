import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Box, Typography, Grid, Paper, useTheme } from "@mui/material";
import '@mdxeditor/editor/style.css'
import '@/pages/Wiki/styles.css'
import { 
  MDXEditor, 
  Button,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  codeBlockPlugin, 
  thematicBreakPlugin,
  markdownShortcutPlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  MDXEditorMethods,
  CodeToggle,
  InsertCodeBlock,
  codeMirrorPlugin,
  ChangeCodeMirrorLanguage,
  ConditionalContents,
  ListsToggle,
  CodeMirrorEditor,
  useCodeBlockEditorContext,
  CodeBlockEditorDescriptor,
  linkPlugin,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
} from '@mdxeditor/editor'
import { githubDark } from "@uiw/codemirror-theme-github";
import { useWiki } from "../context/WikiContext";
import debounce from 'lodash/debounce';
import { getTemplates } from "@/services/Blar/Wiki";

interface EditorToolbarProps {
  title: string;
  onTitleDoubleClick: () => void;
}

const EditorToolbar = React.memo(({ title, onTitleDoubleClick }: EditorToolbarProps) => (
  <Box display="flex" sx={{ width: '100%', justifyContent: 'space-between' }}>
    <Box display="flex" >
      <DiffSourceToggleWrapper options={['rich-text', 'source']}>
        <UndoRedo />
        <ConditionalContents
          options={[
            {
              when: (editor) => editor?.editorType === 'codeblock',
              contents: () => <ChangeCodeMirrorLanguage />
            },
            { 
              fallback: () => (
                <>
                  <BoldItalicUnderlineToggles />
                  <BlockTypeSelect />
                  <CodeToggle />
                  <ListsToggle />
                  <InsertCodeBlock /> 
                </>
              )
            }
          ]}
        />
      </DiffSourceToggleWrapper>
    </Box>
    <Box display="flex" gap={1}>
      <Box display="flex" alignItems="center">
        <Typography 
          variant="h6" 
          data-title-input
          onDoubleClick={onTitleDoubleClick}
          sx={{ 
            fontSize: '1.25rem', 
            lineHeight: '1.5',
            cursor: 'text',
            padding: '3px 6px',
            borderRadius: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '300px',
            '&[contenteditable="true"]': {
              background: 'rgba(255, 255, 255, 0.1)',
              outline: 'none',
            },
            '&[contenteditable="true"]:focus': {
              background: 'rgba(255, 255, 255, 0.15)',
            }
          }}
        >
          {title}
        </Typography>
      </Box>
      <Button className="save-button" type="submit">
        Save
      </Button>
    </Box>
  </Box>
));

EditorToolbar.displayName = 'EditorToolbar';

const PlainTextCodeEditorDescriptor: CodeBlockEditorDescriptor = {
  match: () => true,
  priority: 0,
  Editor: React.memo((props) => {
    const cb = useCodeBlockEditorContext()
    return (
      <CodeMirrorEditor {...props} />
    )
  })
};

const FileEditor: React.FC = () => {
  const {
    selectedNode,
    inputFileContent,
    setOutputFileContent,
    handleSaveFile,
    title,
    handleRename,
    setEditorRef,
    setWikiHasUnsyncedChanges,
  } = useWiki();

  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [isEditorReady, setIsEditorReady] = useState<boolean>(false);
  const editorRef = React.useRef<MDXEditorMethods>(null);

  React.useEffect(() => {
    setEditorRef(editorRef);
    setIsEditorReady(true);
    return () => {
      setIsEditorReady(false);
    };
  }, [setEditorRef]);

  // Add effect to handle focus when content is empty
  React.useEffect(() => {
    if (isEditorReady && selectedNode?.type === 'file' && !inputFileContent) {
      editorRef.current?.focus();
    }
  }, [isEditorReady, selectedNode, inputFileContent]);

  const handleContentChange = useCallback(
    debounce((content: string) => {
      if (content !== inputFileContent) {
        setWikiHasUnsyncedChanges(true);
      }
    }, 100),
    [setWikiHasUnsyncedChanges, inputFileContent]
  );

  const handleTitleDoubleClick = useCallback(() => {
    if (!selectedNode) return;
    
    setIsEditingTitle(true);
    setTimeout(() => {
      const titleElement = document.querySelector('[data-title-input]') as HTMLElement;
      if (titleElement) {
        titleElement.setAttribute('contenteditable', 'true');
        titleElement.focus();
        const range = document.createRange();
        range.selectNodeContents(titleElement);
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }

        const handleBlur = async () => {
          const newTitle = titleElement.textContent || '';
          titleElement.removeAttribute('contenteditable');
          if (newTitle && newTitle !== title && selectedNode) {
            await handleRename(selectedNode, newTitle);
          }
          titleElement.removeEventListener('blur', handleBlur);
          titleElement.removeEventListener('keydown', handleKeyDown);
          setIsEditingTitle(false);
        };

        const handleKeyDown = (e: Event) => {
          const keyEvent = e as KeyboardEvent;
          if (keyEvent.key === 'Enter') {
            e.preventDefault();
            titleElement.blur();
          }
          if (keyEvent.key === 'Escape') {
            titleElement.textContent = title;
            titleElement.blur();
          }
        };

        titleElement.addEventListener('blur', handleBlur);
        titleElement.addEventListener('keydown', handleKeyDown);
      }
    }, 0);
  }, [selectedNode, title, handleRename]);

  const plugins = useMemo(() => [
    headingsPlugin(),
    listsPlugin(),
    quotePlugin(),
    diffSourcePlugin({ viewMode: 'rich-text' }),
    thematicBreakPlugin(),
    markdownShortcutPlugin(),
    linkPlugin(),
    codeBlockPlugin({
      defaultCodeBlockLanguage: 'python',
      codeBlockEditorDescriptors: [PlainTextCodeEditorDescriptor]
    }),
    codeMirrorPlugin({ 
      codeBlockLanguages: { 
        python: 'Python',
        javascript: 'JavaScript',
        typescript: 'TypeScript',
        jsx: 'JSX',
        tsx: 'TSX',
        css: 'CSS',
        html: 'HTML',
        json: 'JSON',
      },
      codeMirrorExtensions: [githubDark],
    }),
    toolbarPlugin({
      toolbarClassName: "",
      toolbarContents: () => (
        <EditorToolbar title={title} onTitleDoubleClick={handleTitleDoubleClick} />
      )
    })
  ], [title, handleTitleDoubleClick]);

  if (!selectedNode || !isEditorReady) return null;

  return (
    <form 
      onSubmit={(e) => {
        e.preventDefault();
        handleSaveFile();
      }}
    >
      <Box
        textAlign="left"
        width="100%"
        px={2} 
        sx={{ 
          overflow: 'hidden',
          mt: 2,
        }}
      >
        <MDXEditor
          key={selectedNode.id}
          markdown={inputFileContent || ""}
          ref={editorRef}
          onChange={handleContentChange}
          plugins={plugins}
          contentEditableClassName="prose-content"
          className="dark-editor"
        />
      </Box>
    </form>
  );
};

export default React.memo(FileEditor);
