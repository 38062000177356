import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Alert,
} from "@mui/material";
import {
  ArrowBack,
  ThumbUp as ThumbUpIcon,
  ThumbUpOffAlt as ThumbUpOffAltIcon,
  ThumbDown as ThumbDownIcon,
  ThumbDownOffAlt as ThumbDownOffAltIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNightBright as codeStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";
import WikiAgentDescription from "./WikiAgentDescription";
import TagifyComponent from "./WikiChatInput";
import useStyles from "../../Chat/components/styles/ChatBody";
import { FileNode } from "../types";
import { AgentStep } from "@/pages/Chat/components/types";
import { TagifyProvider } from "@/pages/Chat/context/TagifyContext";
import FeedbackBox from "@/pages/Chat/components/Chat/Feedback";
import LoadingDots from "@/components/Animations/LoadingDots";
import CopyButton from "@/components/Buttons/CopyButton";
import Timestamp from "@/components/Typographies/Timestamp";
import UserAvatar from "@/components/User/UserAvatar";
import RenderUserMessage from "@/pages/Chat/components/Chat/RenderUserMessage";
import { mapMessageUserToUser } from "@/pages/Chat/components/helpers";
import { useWiki } from "../context/WikiContext";
import WikiCanvas from "./WikiCanvas";

interface FileChatProps { 
  selectedNode: FileNode | null;
  setIsFileChatView: (isFileChatView: boolean) => void;
  messages: any[];
  setMessages: (messages: any[]) => void;
  agentStep: AgentStep | null;
}

const FileChat = ({ 
  selectedNode,
  setIsFileChatView,
  messages,
  setMessages,
  agentStep: externalAgentStep,
}: FileChatProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const messageListEndRef = useRef<HTMLDivElement>(null);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessageId, setFeedbackMessageId] = useState<number | null>(null);
  const [agentStep, setAgentStep] = useState<AgentStep | null>(externalAgentStep);
  const [processedWikiContent] = useState(() => new Set<string>());
  const { handleSendMessage, loadingResponse, updateEditorContent } = useWiki();

  useEffect(() => {
    setAgentStep(externalAgentStep);
  }, [externalAgentStep]);

  useEffect(() => {
    if (messageListEndRef.current) {
      messageListEndRef.current.scrollIntoView({ 
        behavior: 'smooth',
        block: 'end'
      });
    }
  }, [messages, agentStep, loadingResponse]);

  const getName = (message: any) => {
    return message.user.first_name
      ? message.user.first_name + " " + message.user.last_name
      : message.user.email;
  };

  const handleThumbsUp = async (messageId: number) => {
    const newMessages = messages.map((msg: any) =>
      msg.id === messageId
        ? { ...msg, user_thumbs_up: true, user_thumbs_down: false }
        : msg
    );
    setMessages(newMessages);
    setFeedbackMessageId(messageId);
    setFeedbackOpen(true);
  };

  const handleThumbsDown = async (messageId: number) => {
    const newMessages = messages.map((msg: any) =>
      msg.id === messageId
        ? { ...msg, user_thumbs_up: false, user_thumbs_down: true }
        : msg
    );
    setMessages(newMessages);
    setFeedbackMessageId(messageId);
    setFeedbackOpen(true);
  };

  const processMessageContent = (message: string) => {
    // Split by wiki tags while preserving newlines
    const parts = message.split(/(<wiki>[\s\S]*?<\/wiki>)/);
    return parts.map((part, index) => {
      if (part.startsWith('<wiki>') && part.endsWith('</wiki>')) {
        const wikiContent = part.replace(/<\/?wiki>/g, '');
        return <WikiCanvas key={index} classes={classes}>{wikiContent}</WikiCanvas>;
      }
      return (
        <ReactMarkdown
          key={index}
          rehypePlugins={[rehypeRaw]}
          components={{
            code(props) {
              const { children, className, ...rest } = props;
              const match = /language-(\w+)/.exec(className || "");
              return match ? (
                <div style={{ position: "relative" }}>
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, "")}
                    language={match[1]}
                    style={codeStyle}
                    customStyle={{
                      borderRadius: "20px",
                      padding: "20px",
                      fontSize: "14px",
                    }}
                  />
                  <Box className={classes.copyButton}>
                    <CopyButton
                      copyValue={String(children).replace(/\n$/, "")}
                      backgroundColor="black"
                      size="small"
                      sx={{
                        height: "20px",
                      }}
                    />
                  </Box>
                </div>
              ) : (
                <code
                  {...rest}
                  className={className}
                  style={{
                    textOverflow: "ellipsis",
                    wordBreak: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {children}
                </code>
              );
            },
          }}
        >
          {part}
        </ReactMarkdown>
      );
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1, 
          p: 2,
          flexShrink: 0,
        }}
      >
        <IconButton onClick={() => setIsFileChatView(false)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {selectedNode?.name}
        </Typography>
      </Box>

      <Box 
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        {messages.length ? (
          <List className={classes.messageList}>
            {messages.map((message) => (
            <ListItem
              key={message.id}
              sx={{
                justifyContent: message.is_blar ? "flex-start" : "flex-end",
                alignItems: "flex-start",
              }}
            >
              {message.is_blar && (
                <img
                  src="/logos/GreyLogo.svg"
                  alt="Blar"
                  className={classes.avatar}
                />
              )}

              <Paper
                className={classes.message}
                sx={{
                  backgroundColor: message.is_blar
                    ? theme.palette.background.paper
                    : theme.palette.divider,
                  border: "1px solid",
                  borderColor: theme.palette.divider,
                }}
              >
                <Typography
                  textAlign={"start"}
                  variant="body2"
                  sx={{ fontStyle: "italic" }}
                >
                  {message.is_blar ? "Blar" : getName(message)}
                </Typography>
                <ListItemText
                  sx={{
                    textOverflow: "ellipsis",
                    wordBreak: "break-word",
                  }}
                  primary={
                    message.is_blar ? (
                      processMessageContent(message.message)
                    ) : (
                      <RenderUserMessage message={message.message} />
                    )
                  }
                />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    {message.is_blar && (
                      <>
                        <IconButton
                          size="small"
                          onClick={() => handleThumbsUp(message.id!)}
                        >
                          {message.user_thumbs_up ? (
                            <ThumbUpIcon fontSize="small" />
                          ) : (
                            <ThumbUpOffAltIcon fontSize="small" />
                          )}
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleThumbsDown(message.id!)}
                        >
                          {message.user_thumbs_down ? (
                            <ThumbDownIcon fontSize="small" />
                          ) : (
                            <ThumbDownOffAltIcon fontSize="small" />
                          )}
                        </IconButton>
                      </>
                    )}
                  </Box>
                  <Box className={classes.timestamp}>
                    <Timestamp timestamp={message.created_at} />
                  </Box>
                </Box>
              </Paper>

              {!message.is_blar && (
                <Box m={1}>
                  <UserAvatar user={mapMessageUserToUser(message.user)} />
                </Box>
              )}
            </ListItem>
          ))}
          {agentStep && (
            <ListItem
              key={agentStep.step + agentStep.type}
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img
                src="/logos/GreyLogo.svg"
                alt="Blar"
                className={classes.avatar}
              />
              Exploring {agentStep.response.node_name} inside{" "}
              {agentStep.response.file_path.split("/").slice(-3).join("/")}
              <LoadingDots />
            </ListItem>
          )}

          {loadingResponse && (
            <ListItem
              key={-1}
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img
                src="/logos/GreyLogo.svg"
                alt="Blar"
                className={classes.avatar}
              />
              Blar is thinking...
              <LoadingDots />
            </ListItem>
          )}
          <div ref={messageListEndRef} />

          {feedbackOpen && (
            <ListItem
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <FeedbackBox
                setFeedbackOpen={setFeedbackOpen}
                feedbackMessageId={feedbackMessageId}
              />
            </ListItem>
          )}
        </List>
      ) : (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}>
          <WikiAgentDescription />
        </Box>
      )}
      </Box>

      <TagifyComponent
        sendMessage={handleSendMessage}
        loading={loadingResponse}
        messages={messages}
      />

    </Box>
  );
};

export default FileChat; 