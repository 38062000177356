import { Repo } from "@/pages/Settings/components/Repos/types";
import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RepoChecklistPercentage } from "../components/RepoList";
import { list_repo_checklist_percentage } from "@/services/Blar/Checklist";
import { formatRepoName } from "../helpers";

interface ChecklistContextType {
  selectedRepo: RepoChecklistPercentage | null;
  setSelectedRepo: (repo: RepoChecklistPercentage | null) => void;
  handleSelectRepo: (repo: RepoChecklistPercentage) => void;
}

const ChecklistContext = createContext<ChecklistContextType>({} as ChecklistContextType);

export const useChecklistContext = () => useContext(ChecklistContext);

export const ChecklistContextProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { repoName } = useParams();
  const [selectedRepo, setSelectedRepo] = useState<RepoChecklistPercentage | null>(null);

  useEffect(() => {
    async function syncRepoFromUrl() {
      if (repoName && !selectedRepo) {
        const reposResult = await list_repo_checklist_percentage();
        const repo = reposResult.data.find((r: RepoChecklistPercentage) => {
          const repoNameWithoutOrg = formatRepoName(r.name, false).toLowerCase();
          const urlRepoName = repoName.toLowerCase();
          return repoNameWithoutOrg === urlRepoName ||
                 repoNameWithoutOrg.replace(/_/g, '-') === urlRepoName;
        });
        if (repo) {
          setSelectedRepo(repo);
        }
      }
    }
    syncRepoFromUrl();
  }, [repoName, selectedRepo]);

  const handleSelectRepo = (repo: RepoChecklistPercentage) => {
    setSelectedRepo(repo);
    navigate(`/checklist/${formatRepoName(repo.name, false)}`);
  };

  return <ChecklistContext.Provider value={{ selectedRepo, setSelectedRepo, handleSelectRepo }}>{children}</ChecklistContext.Provider>;
};